<template>
    <div class="sort-filter sort-boxes">
        <fly-out location="bottom" ref="flyOut" @hide="filter">
            <template #button>
                <div class="wrapper">
                    <div class="label">
                        {{ label }}:
                    </div>
                    <div class="caret"></div>
                </div>
            </template>

            <div class="row">
                <div class="col-6" v-for="item of list">
                    <div class="form-check">
                        <input type="checkbox" :id="item.short_code" :name="item.short_code" v-model="filters" :value="item.id" class="form-check-input">
                        <label :for="item.short_code" class="form-check-label small">{{ item.name }}</label>
                    </div>
                </div>
            </div>
        </fly-out>
    </div>
</template>

<script>
    export default {
        name: "GiftPassionFilter",
        props: {
            label: String,
            list: Array
        },
        data() {
            return {
                filters: []
            }
        },
        methods: {
            filter() {
                this.$emit('filter', this.filters)
            }
        }
    }
</script>

<style scoped>

</style>