<template>
    <div class="d-flex flex-row">
        <sort-filter class="mr-5" :categories="sortList"></sort-filter>
    </div>
</template>

<script>
    export default {
        name: "SuperAdminUserFilters",
        data() {
            return {
                giftFilters: [],
                passionFilters: [],
            }
        },
        computed: {
            sortList() {
                return [
                    {key: "nameAsc", value: "Name: A to Z"},
                    {key: "nameDesc", value: "Name: Z to A"},
                ]
            }
        },
        methods: {
            // filterGifts(filters) {
            //     if (!_.isEqual(this.giftFilters, filters)) {
            //         this.giftFilters = filters;
            //         EventBus.$emit('filter', this.passionFilters.concat(filters))
            //     }
            // },
            // filterPassions(filters) {
            //     if (!_.isEqual(this.passionFilters, filters)) {
            //         this.passionFilters = filters;
            //         EventBus.$emit('filter', this.giftFilters.concat(filters))
            //     }
            // },
        }
    }
</script>

<style scoped>

</style>
