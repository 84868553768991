<template>
    <section class="card">
        <assessment-progress
                :questions="numberOfQuestions"
                :complete="answered"
        ></assessment-progress>

        <assessment-welcome-page @next="showGiftsContentPage" v-if="showWelcome" :loading="loading"></assessment-welcome-page>

        <assessment-content-page v-if="showGiftContent" @next="nextPage" @back="showWelcomePage" :loading="loading">
            <template #title>{{ lang.get('assessment.discern-your-spiritual-gifts') }}</template>
            <p v-html="lang.get('assessment.we-are-gods-workmanship')"></p>
            <p v-html="lang.get('assessment.now-about-spiritual-gifts-brothers')"></p>
            <p v-html="lang.get('assessment.when-we-abide-with-jesus')"></p>
            <p v-text="lang.get('assessment.to-discern-the-gifts-you-may-have')"></p>
        </assessment-content-page>

        <assessment-content-page v-if="showPassionContent" @next="hidePassionContent" :go-back="false" :loading="loading">
            <template #title>{{ lang.get('assessment.passions') }}</template>
            <p v-html="lang.get('assessment.take-delight-in-the-lord')"></p>
            <p v-html="lang.get('assessment.we-are-gods-masterpiece')"></p>
            <p v-text="lang.get('assessment.as-we-abide-with-jesus')"></p>

            <gifts-results :gifts="grade.gifts"></gifts-results>
        </assessment-content-page>

        <assessment-content-page v-if="showStoryContent" @next="hideStoryContent" :go-back="false" :loading="loading">
            <template #title>{{ lang.get('assessment.story') }}</template>
            <p v-html="lang.get('assessment.you-saw-me-before-i-was-born')"></p>
            <p v-text="lang.get('assessment.your-life-is-a-story-that-god')"></p>
            <p v-text="lang.get('assessment.we-will-prompt-you-to-think-back')"></p>
            <p v-text="lang.get('assessment.this-reflection-exercise-should-not-be-rushed')"></p>

            <gifts-results :gifts="grade.gifts"></gifts-results>
            <passions-results :passions="grade.passions"></passions-results>
        </assessment-content-page>

        <assessment-content-page v-if="showStoryReflectionContent" @next="hideStoryReflectionContent" :go-back="false" :loading="loading">
            <template #title>{{ lang.get('assessment.story') }}</template>
            <h3 v-text="lang.get('assessment.looking-back-into-your-story')"></h3>
            <p v-text="lang.get('assessment.take-a-few-minutes-to-reflect-on-your-spiritual-gifts')"></p>
            <ul>
                <li v-text="lang.get('assessment.during-my-childhood')"></li>
                <li v-text="lang.get('assessment.during-my-youth')"></li>
                <li v-text="lang.get('assessment.during-my-early-adulthood')"></li>
                <li v-text="lang.get('assessment.during-middle-age')"></li>
                <li v-text="lang.get('assessment.during-my-senior-years')"></li>
            </ul>

            <p v-text="lang.get('assessment.take-a-few-minutes-to-reflect-on-your-abilities')"></p>
            <ul>
                <li v-text="lang.get('assessment.during-my-childhood')"></li>
                <li v-text="lang.get('assessment.during-my-youth')"></li>
                <li v-text="lang.get('assessment.during-my-early-adulthood')"></li>
                <li v-text="lang.get('assessment.during-middle-age')"></li>
                <li v-text="lang.get('assessment.during-my-senior-years')"></li>
            </ul>

            <p v-text="lang.get('assessment.take-a-few-minutes-to-reflect-on-the-people')"></p>
            <ul>
                <li v-text="lang.get('assessment.during-my-childhood')"></li>
                <li v-text="lang.get('assessment.during-my-youth')"></li>
                <li v-text="lang.get('assessment.during-my-early-adulthood')"></li>
                <li v-text="lang.get('assessment.during-middle-age')"></li>
                <li v-text="lang.get('assessment.during-my-senior-years')"></li>
            </ul>

            <p v-text="lang.get('assessment.take-a-few-minutes-to-reflect-influencing-styles')"></p>
            <ul>
                <li v-text="lang.get('assessment.during-my-childhood')"></li>
                <li v-text="lang.get('assessment.during-my-youth')"></li>
                <li v-text="lang.get('assessment.during-my-early-adulthood')"></li>
                <li v-text="lang.get('assessment.during-middle-age')"></li>
                <li v-text="lang.get('assessment.during-my-senior-years')"></li>
            </ul>
        </assessment-content-page>

        <assessment-checkbox-questions-page :options="abilities"
                                            @next="nextPage"
                                            @back="lastPage"
                                            v-show="showAbilitiesCheckboxPage()"
                                            :go-back="false"
                                            :loading="loading"
                                            :other.sync="other"
                                            :other-two.sync="otherTwo"
        ></assessment-checkbox-questions-page>

        <assessment-checkbox-questions-page :options="people"
                                            @next="nextPage"
                                            @back="lastPage"
                                            v-show="showPeopleCheckboxPage()"
                                            :go-back="false"
                                            :loading="loading"
                                            :other.sync="other"
                                            :other-two.sync="otherTwo"
                                            current-header="Passions"
                                            :max-answers="2"
        ></assessment-checkbox-questions-page>

        <assessment-checkbox-questions-page :options="causes"
                                            @next="goToPassionQuestions"
                                            @back="lastPage"
                                            v-show="showCausesCheckboxPage()"
                                            :go-back="false"
                                            :loading="loading"
                                            :other.sync="other"
                                            :other-two.sync="otherTwo"
                                            current-header="Passions"
                                            :max-answers="2"
        ></assessment-checkbox-questions-page>

        <assessment-questions-page
                :current-header="getCurrentHeader()"
                @next="nextPage"
                @back="lastPage"
                :go-back="canGoBack()"
                v-show="showMultiChoiceQuestionPage()"
                :loading="loading"></assessment-questions-page>

         <assessment-text-questions-page current-header="Story"
                                         :loading="loading"
                                         v-show="showTextQuestionPage()"
                                         @next="nextPage"
                                         @back="lastPage"
         >
             <gifts-results :gifts="grade.gifts"></gifts-results>
             <passions-results :passions="grade.passions"></passions-results>
             <div class="px-3 mt-4" v-if="grade">
                 <div class="row mb-3">
                     <div class="col-12 col-md-4">
                         <h3 style="text-transform: capitalize" v-text="lang.get('assessment.abilities')"></h3>
                         <ul v-for="ability of grade.abilities">
                             <li>{{ ability }}</li>
                         </ul>
                     </div>
                     <div class="col-12 col-md-4">
                         <h3 style="text-transform: capitalize" v-text="lang.get('assessment.causes')"></h3>
                         <ul v-for="cause of grade.causes">
                             <li>{{ cause }}</li>
                         </ul>
                     </div>
                     <div class="col-12 col-md-4">
                         <h3 style="text-transform: capitalize" v-text="lang.get('assessment.people')"></h3>
                         <ul v-for="person of grade.people">
                             <li>{{ person }}</li>
                         </ul>
                     </div>
                 </div>
             </div>
         </assessment-text-questions-page>

    </section>
</template>

<script>
    import { EventBus } from "../EventBus";
    import AssessmentProgress from './AssessmentProgress';
    import AssessmentWelcomePage from './AssessmentWelcomePage';
    import AssessmentQuestionsPage from './AssessmentMultiChoiceQuestionsPage';
    import AssessmentContentPage from './AssessmentContentPage';
    import AssessmentTextQuestionsPage from './AssessmentTextQuestionsPage';
    import AssessmentCheckboxQuestionsPage from './AssessmentCheckboxQuestionsPage';

    import ApiService from '../services/ApiService';
    import GiftsResults from "./GiftsResults";
    import PassionsResults from "./PassionsResults";

    export default {
        name: "Assessment",
        components: {
            PassionsResults,
            GiftsResults,
            AssessmentProgress,
            AssessmentWelcomePage,
            AssessmentQuestionsPage,
            AssessmentContentPage,
            AssessmentTextQuestionsPage,
            AssessmentCheckboxQuestionsPage,
        },
        props: {
            assessmentId: {
                type: Number,
                required: true
            },
            initialAnswered: {
                type: Number,
                default() {
                    return 0
                }
            },
            numberOfQuestions: {
                type: Number,
                default() {
                    return 0
                }
            },
            people: Array,
            causes: Array,
            abilities: Array,
        },
        data() {
            return {
                answered: 0,
                currentOffset: 0,
                questions: [],
                previousAnswers: [],
                loading: true,
                currentType: 1,
                currentQuestionType: 1,
                other: '',
                otherTwo: '',
                grade: {},
                // special pages
                showWelcome: false,
                showGiftContent: false,
                showPassionContent: false,
                showStoryContent: false,
                showStoryReflectionContent: false,
            }
        },
        computed: {
            textQuestionLimit() {
                return 2
            },
            lang() {
                return window.lang;
            }
        },
        mounted() {
            this.answered = this.initialAnswered;

            if (this.answered === 0) {
                this.showWelcome = true;
                this.loading = false;
            } else {
                ApiService.assessmentInitialQuestions(this.assessmentId).then(response => {
                    this.loading = false;
                    this.questions = response.questions;
                    this.grade = response.grade;
                    this.previousAnswers = response.answers;
                    this.currentOffset = response.offset;

                    this.currentType = this.questions[0].type_id;
                    this.currentQuestionType = this.questions[0].question_type_id;

                    this.changeQuestionViewBasedOnType(this.questions, {
                        questions: response.questions,
                        previousAnswers: response.answers,
                    })
                }).catch(error => {
                    this.loading = false;
                    console.log(error);
                })
            }
        },
        methods: {
            showWelcomePage() {
                this.showWelcome = true;
                this.showGiftContent = false;
                this.loading = false;
            },

            showGiftsContentPage() {
                this.showWelcome = false;
                this.showGiftContent = true;
                this.loading = false;
            },

            changeQuestionViewBasedOnType(questionArray, emitter=null) {
                let filteredQuestions = [];
                let modifiedEmitter = emitter;

                for(let i = 0; i < questionArray.length; i++) {
                    let target = questionArray[i];

                    if (target.type_id === this.currentType && target.question_type_id === this.currentQuestionType) {
                        this.showGiftContent = false;

                        filteredQuestions.push(target);

                        // limits checkboxes questions to one question per page
                        if (this.currentQuestionType === 2 && filteredQuestions.length === 1) {
                            break;
                        }

                        // limits text questions to two question per page
                        if (this.currentQuestionType === 3 && filteredQuestions.length === this.textQuestionLimit) {
                            break;
                        }
                    } else {
                        if (filteredQuestions.length === 0) {
                            if (target.type_id !== this.currentType) {
                                this.currentType = target.type_id;
                            }
                            if (target.question_type_id !== this.currentQuestionType) {
                                this.currentQuestionType = target.question_type_id;
                            }
                        }
                        break;
                    }
                }

                // Conditionally show/hide specific content
                if (filteredQuestions.length === 0) {
                    if (this.currentType === 2) {
                        if (questionArray[0].passion_type === 'Cause') {
                            EventBus.$emit('setNewQuestions', { questions: [ questionArray[0] ] });
                        } else {
                            this.showPassionContent = true;
                            EventBus.$emit('setNewQuestions', { questions: [] });
                        }
                    } else if (this.currentType === 3) {
                        this.showStoryContent = true;
                        EventBus.$emit('setNewQuestions', { questions: [] });
                    }

                    // to show checkbox inputs
                    if (this.currentQuestionType === 2) {
                        for(let i = 0; i < questionArray.length; i++) {
                            let target = questionArray[i];

                            if (target.type_id === this.currentType && target.question_type_id === this.currentQuestionType) {
                                filteredQuestions.push(target);

                                if (filteredQuestions.length === 1) {
                                    break;
                                }
                            }
                        }
                    }
                }

                if (emitter) {
                    modifiedEmitter.questions = filteredQuestions;
                    EventBus.$emit('setNewQuestions', modifiedEmitter);
                } else {
                    EventBus.$emit('setNewQuestions', { questions: filteredQuestions});
                }
            },

            /**
             * @param {Array<Object>|null} answers
             */
            nextPage(answers) {
                if (answers) {
                    if (this.currentQuestionType === 1 || this.currentQuestionType === 3) {
                        this.currentOffset += answers.length
                    } else {
                        // is checkbox
                        this.currentOffset++
                    }
                }

                this.loading = true;

                ApiService.assessmentNextPage(this.assessmentId, this.currentOffset, answers, this.currentType).then(response => {
                    this.showWelcome = false;
                    this.questions = response.questions;
                    this.grade = response.grade;
                    this.currentOffset = response.offset;

                    let emitter = {
                        questions: response.questions
                    };
                    if (response.answersToQuestions.length > 0) {
                        emitter.previousAnswers = response.answersToQuestions
                    } else {
                        this.answered = response.answered;
                    }

                    // is complete
                    if (response.complete) {
                        return window.location = `/assessment/${this.assessmentId}`;
                    } else {
                        this.loading = false;
                    }

                    this.changeQuestionViewBasedOnType(this.questions, emitter);
                }).catch(error => {
                    this.loading = false;
                    console.log(error);
                })
            },

            goToPassionQuestions(answers) {
                this.currentOffset++
                this.loading = true;

                ApiService.assessmentNextPage(this.assessmentId, this.currentOffset, answers, this.currentType).then(response => {
                    this.loading = false;
                    this.questions = response.questions;
                    // this.answered++

                    this.currentQuestionType = 1;
                    this.currentType = 2;

                    let emitter = {
                        questions: response.questions
                    };
                    if (response.answersToQuestions.length > 0) {
                        emitter.previousAnswers = response.answersToQuestions
                    } else {
                        this.answered++
                    }

                    this.changeQuestionViewBasedOnType(this.questions, emitter);
                }).catch(error => {
                    this.loading = false;
                    console.log(error);
                })
            },

            /**
             * @param {Array<Object>|null} answers
             */
            lastPage(answers) {
                this.loading = true;

                // Adds to the total answer tally

                if (answers) {
                    if (this.currentQuestionType === 1) {
                        if (this.questions.length < 5) {
                            this.currentOffset -= answers.length
                        } else {
                            this.currentOffset = this.currentOffset - 5;
                        }
                    } else if (this.currentQuestionType === 3) {
                        if (answers.length < this.textQuestionLimit) {
                            this.currentOffset -= answers.length
                        } else {
                            this.currentOffset = this.currentOffset - this.textQuestionLimit;
                        }
                    }
                }

                ApiService.assessmentPreviousPage(this.assessmentId, this.currentOffset, answers).then(response => {
                    this.showWelcome = false;
                    this.loading = false;
                    this.questions = response.questions;
                    this.grade = response.grade;
                    this.currentOffset = response.offset;
                    this.answered = response.answered;

                    this.changeQuestionViewBasedOnType(this.questions, {
                        questions: response.questions,
                        previousAnswers: response.answersToQuestions
                    });
                }).catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            },

            hidePassionContent() {
                this.showPassionContent = false;

                let nextQuestion = this.questions[0];
                this.currentType = nextQuestion.type_id;
                this.currentQuestionType = nextQuestion.question_type_id;
                EventBus.$emit('setNewQuestions', { questions: [ nextQuestion ]});
            },

            hideStoryContent() {
                this.showStoryContent = false;
                this.showPassionContent = false;
                this.showStoryReflectionContent = true;
            },

            hideStoryReflectionContent() {
                this.showStoryReflectionContent = false;

                let nextQuestion = this.questions[0];
                this.currentType = nextQuestion.type_id;
                this.currentQuestionType = nextQuestion.question_type_id;

                // limits next set of questions to 2
                let newQuestions = _.take(this.questions, this.textQuestionLimit);
                EventBus.$emit('setNewQuestions', { questions: newQuestions });
            },

            canGoBack() {
                return this.currentOffset > 0;
            },

            showMultiChoiceQuestionPage() {
                if (this.showWelcome === false && this.showGiftContent === false && this.showPassionContent === false) {
                    return this.currentQuestionType === 1;
                }
            },

            showTextQuestionPage() {
                if (this.showWelcome === false && this.showGiftContent === false && this.showPassionContent === false && this.showStoryContent === false && this.showStoryReflectionContent === false) {
                    return this.currentQuestionType === 3;
                }
            },

            showAbilitiesCheckboxPage() {
                if (this.showWelcome === false && this.showGiftContent === false && this.showPassionContent === false) {
                    return this.currentQuestionType === 2 && this.currentType === 1;
                }
            },

            showPeopleCheckboxPage() {
                if (this.showWelcome === false && this.showGiftContent === false && this.showPassionContent === false) {
                    return this.currentQuestionType === 2 && this.currentType === 2 && this.questions[0].passion_type === 'People';
                }
            },

            showCausesCheckboxPage() {
                if (this.showWelcome === false && this.showGiftContent === false && this.showPassionContent === false) {
                    return this.currentQuestionType === 2 && this.currentType === 2 && this.questions[0].passion_type === 'Cause';
                }
            },

            getCurrentHeader() {
                if (this.currentType === 1) {
                    return lang.get('assessment.gifts')
                } else if (this.currentType === 2) {
                    return lang.get('assessment.passions')
                }

                return lang.get('assessment.story')
            }
        }
    }
</script>

<style scoped>

</style>
