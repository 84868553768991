<template>
    <div :class="themeClass">
        <div class="loading-text" v-if="!spinner">
            Loading...
        </div>
        <div class="loading-text spinner" v-if="spinner">
            <div class="typing_loader"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        props: {
            theme: {
                type: String,
                default() {
                    return 'dark'
                }
            },
            size: {
                type: String,
                default() {
                    return ''
                }
            },
            spinner: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        computed: {
            themeClass() {
                return `loader ${this.theme} ${this.size}`
            }
        }
    }
</script>

<style scoped>
    .loader {
        position: relative;
        left: auto;
        top: auto;
        width: auto;
        height: auto;
        flex: 1;
    }
    /* colors */
    .loader.light {
        background-color: rgb(244,244,244);
    }
    .loader.white {
        background-color: rgb(255,255,255);
    }

    /* size */
    .loader.large {
        min-height: 300px;
    }
</style>