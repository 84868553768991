export default {
    computed: {
        lang() {
            return window.lang
        }
    },
    methods: {
        parseQuestion(question) {
            switch (this.lang.locale) {
                case 'es':
                    return question.question_es;
                default:
                    return question.question;
            }
        }
    }
}
