<template>
    <div class="container">
        <div class="mb-3 mb-lg-5">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-6 text-center">
                    <h1 class="text-normal-case">{{ lang.get('packages.purchase-your-package') }}</h1>
                    <p class="text-lighter">{{ lang.get('packages.please-fill-payment') }}</p>

                </div>
            </div>
            <div class="row justify-content-center mb-3 mb-lg-4">
                <div class="col-md-12 col-lg-6">
                    <div class="card package">
                        <div class="row">
                            <div class="col-12 d-flex align-items-center">
                                <div class="price flex-row mr-2 mr-lg-3">
                                    {{ lang.get('packages.price') }}
                                    <div class="number">
                                        ${{ getPrice() }}
                                    </div>
                                </div>
                                <div class="badge blue" v-if="package === 'silver'">{{ lang.get('packages.silver') }}</div>
                                <div class="badge orange" v-if="package === 'gold'">{{ lang.get('packages.gold') }}</div>
                                <div class="badge green" v-if="package === 'platinum'">{{ lang.get('packages.platinum') }}</div>
                            </div>
                        </div>

                        <sign-up-errors class="my-3" :errors="errors"></sign-up-errors>

                        <sign-up-errors class="my-3" :errors="couponErrors"></sign-up-errors>

                        <form @submit.prevent="applyCoupon" class="row form-group mt-3" v-if="!discountedPrice || discountedPrice === null">
                            <div class="col-8">
                                <input type="text" class="form-input" v-model="promoCode" :placeholder="lang.get('forms.promo-code')" :disabled="thinking || promoLoading" required />
                            </div>
                            <div class="col-4">
                                <button type="submit" class="button block" :disabled="thinking || promoLoading" v-text="lang.get('forms.apply')"></button>
                            </div>
                        </form>
                        <div class="bg-light border-radius-3 p-3 my-3" v-if="discountedPrice || discountedPrice === 0">
                            <p class="mb-0" v-text="lang.get('forms.code-has-been-applied')"></p>
                        </div>
                        <hr>
                        <form @submit.prevent="submitPayment">
                            <div class="row form-group">
                                <div class="col-8">
                                    <input type="text" class="form-input card-number" :placeholder="lang.get('forms.card-number')" v-model="interface.card_number" :disabled="thinking" required />
                                </div>
                                <div class="col-4">
                                    <input type="text" class="form-input" placeholder="cvv" v-model="interface.cvv" :disabled="thinking" required />
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-6">
                                    <input type="text" class="form-input" placeholder="MM" v-model="interface.exp_month" :disabled="thinking" required maxlength="2" />
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-input" placeholder="YYYY" v-model="interface.exp_year" :disabled="thinking" required maxlength="4" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <button @click="$emit('back')" type="button" class="button w-icon secondary" :disabled="thinking" v-html="lang.get('pagination.previous')"></button>
                                </div>
                                <div class="col-6 text-right">
                                    <button type="submit" class="button large primary" :disabled="thinking" v-html="lang.get('forms.submit')"></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SignUpErrors from './SignUpErrors';
    import PaymentInformation from "../mixins/PaymentInformation";
    import Cleave from 'cleave.js';

    export default {
        name: "PaymentInformation",
        components: {
            SignUpErrors
        },
        mixins: [
            PaymentInformation,
        ],
        mounted() {
            new Cleave('.card-number', {
                creditCard: true
            });

            window.scrollTo(0,0);
        },
    }
</script>

<style scoped>

</style>
