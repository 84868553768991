<template>
    <div class="row py-3 my-3 border-top border-bottom">
        <div class="col-8">
            <h3>Administrators</h3>
            <div class="row pb-2 mb-3 border-bottom" v-for="(admin, index) of admins" :key="admin.id">
                <div class="col-8">
                    {{ `${admin.first_name} ${admin.last_name}` }}
                </div>
                <div class="col-4 text-right">
                    <button type="button" :disabled="loading || admins.length === 1" @click="deleteUser(index, admin)" class="button icon trash ml-3"></button>
                </div>
            </div>
            <div v-if="churchUsers.length > 0">
                <p class="font-weight-semi-bold">Add new administrator</p>
                <div class="form-group mb-3">
                    <select class="form-input" v-model="adminToAdd">
                        <option value="" disabled>-- Select --</option>
                        <option v-for="user of churchUsers" :value="user.id">{{ `${user.first_name} ${user.last_name}` }}</option>
                    </select>
                </div>
                <button type="button" @click="addUser" class="button primary thin" :disabled="loading || !adminToAdd || adminToAdd.length === 0">Add</button>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "../services/ApiService";

    export default {
        name: "SuperAdminChurchAdminSelector",
        props: {
            users: {
                type: Array,
                required: true
            },
            organizationId: Number
        },
        data() {
            return {
                admins: [],
                churchUsers: [],
                loading: false,
                adminToAdd: ''
            }
        },
        mounted() {
            for (let i = 0; i < this.users.length; i++) {
                let user = this.users[i];
                let role = user.roles ? user.roles[0] : null;

                if (role) {
                    if (role.id === 3) {
                        this.admins.push(user);
                    } else {
                        this.churchUsers.push(user);
                    }
                } else {
                    this.churchUsers.push(user);
                }
            }
        },
        methods: {
            deleteUser(index, user) {
                this.loading = true;

                ApiService.toggleUserRole(user.id, this.organizationId, 1).then(response => {
                    this.loading = false;
                    this.admins.splice(index, 1);

                    this.churchUsers.push(user);
                    this.churchUsers = _.orderBy(this.churchUsers, ['last_name', 'first_name']);
                }).catch(error => {
                    console.error(error)
                })

                this.admins.splice(index, 1);
            },

            addUser() {
                this.loading = true;

                ApiService.toggleUserRole(this.adminToAdd, this.organizationId, 3).then(response => {
                    let index = _.findIndex(this.churchUsers, {id: this.adminToAdd});
                    let user = this.churchUsers[index];
                    this.loading = false;
                    this.churchUsers.splice(index, 1);
                    this.admins.push(user);

                    this.admins = _.orderBy(this.admins, ['last_name', 'first_name']);
                    this.adminToAdd = '';
                }).catch(error => {
                    console.error(error)
                })
            }
        }
    }
</script>

<style scoped>

</style>
