<template>
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <h2 class="card-title">
                    <slot></slot>
                </h2>
            </div>

            <super-admin-search></super-admin-search>
        </div>
        <div class="row mt-3">
            <div class="col-6">
                <super-admin-user-filters></super-admin-user-filters>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <!--
                <fly-out class="primary" location="bottom">
                    <template #button>
                        <button class="button secondary primary w-icon icon-right">
                            Assign to Church
                            <span class="gps-icon caret"></span>
                        </button>
                    </template>
                    <button type="button" class="menu-item plain">Assign to Church</button>
                    <button type="button" class="menu-item plain danger">Delete</button>
                </fly-out>
                <button type="button" class="button primary ml-2">
                    Apply
                </button>
                -->
                <button type="button" class="button primary ml-2 thin" @click="assign" :disabled="selectedUsers.length === 0">
                    Assign to Church
                </button>
                <button type="button" class="button danger ml-2 thin" @click="reset" v-if="selectedUsers.length > 0">
                    Reset
                </button>
            </div>
        </div>

        <assign-to-church-modal :users="selectedUsers" :visible.sync="assignModalVisible" @done="assignComplete"></assign-to-church-modal>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";
    import AssignToChurchModal from "./AssignToChurchModal";

    export default {
        name: "SuperAdminUserUtilityBar",
        components: {
            AssignToChurchModal
        },
        data() {
            return {
                selectedUsers: [],
                assignModalVisible: false,
            }
        },
        mounted() {
            EventBus.$on('userMassSelect', userArray => {
                this.selectedUsers = userArray;
            });
        },
        methods: {
            reset() {
                EventBus.$emit('resetUserMassSelect');
            },
            assign() {
                this.assignModalVisible = true;
            },
            assignComplete() {
                location.reload();
            }
        }
    }
</script>

<style scoped>

</style>
