import ApiService from "../services/ApiService";
import Cleave from 'cleave.js';

export default {
    name: "PaymentInformation",
    props: {
        paymentData: Object,
        packageList: Array,
        package: String,
        thinking: {
            type: Boolean,
            default() {
                return false
            }
        },
        errors: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            promoLoading: false,
            discountedPrice: null,
            promoCode: '',
            couponErrors: [],
            currentPackage: '',
        }
    },
    mounted() {
        let cardInput = document.getElementsByClassName('card-number');
        if (cardInput && cardInput.length > 0) {
            new Cleave('.card-number', {
                creditCard: true
            });
        }
    },
    computed: {
        lang() {
            return window.lang
        },
        interface: {
            get() {
                return this.paymentData
            },
            set(val) {
                this.$emit('update:paymentData', val);
            }
        },
    },
    methods: {
        isUS() {
            return this.country === 'United States'
        },
        applyCoupon() {
            this.promoLoading = true;
            this.couponErrors = [];

            ApiService.retrieveCoupon(this.promoCode, this.getPrice()).then(response => {
                this.discountedPrice = response.total;
                this.promoLoading = false;
            }).catch(error => {
                let vm = this;
                console.error(error);
                this.promoLoading = false;
                vm.couponErrors = error.errors;
                setTimeout(() => {
                    vm.couponErrors = [];
                }, 3000)
            })
        },

        /**
         * @returns {null|number|Number|*}
         */
        getPrice() {
            if (this.discountedPrice || this.discountedPrice === 0) {
                return this.discountedPrice;
            } else {
                for(let i = 0; i < this.packageList.length; i++) {
                    if (this.packageList[i].name === (this.package || this.currentPackage)) {
                        return this.packageList[i].price;
                    }
                }
            }
        },

        submitPayment() {
            this.couponErrors = [];

            this.$emit('submit', {
                price: this.getPrice() * 100
            });
        }
    }
}
