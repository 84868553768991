<template>
    <loader v-if="loading" :spinner="true" theme="white" size="large"></loader>
    <div class="d-flex p-5 justify-content-center align-items-center align-content-center" v-else-if="!loading && organizations.length === 0">
        <div class="bg-gray-1 p-5 border-radius-2">
            <h3>We couldn't find any more.</h3>
            <p>Please refine your search to get additional results.</p>
        </div>
    </div>
    <table class="table" v-else>
        <thead>
        <tr>
            <th>Church Name</th>
            <th># of Users</th>
            <th>Location</th>
            <th>Admin</th>
            <th>Package</th>
            <th>Actions</th>
        </tr>
        </thead>
        <tbody>
            <tr v-for="organization of organizations" :key="organization.id">
                <td class="vertical-align-middle">{{ organization.name }}</td>
                <td class="vertical-align-middle">
                    {{ organization.users.length }}
                </td>
                <td class="vertical-align-middle">
                    {{ `${organization.city}, ${organization.state}` }}
                </td>
                <td class="vertical-align-middle">
                    {{ getAdminName(organization) }}
                </td>
                <td class="vertical-align-middle">
                    <span class="badge small"
                          :class="[packageBadge(organization.package)]"
                          v-if="organization.package"
                    >{{ organization.package }}</span>
                </td>
                <td class="vertical-align-middle">
                    <div class="d-flex align-items-center">
                        <a :href="editLink(organization)" class="button thin">Edit</a>
                        <!--
                        <delete-user-button class="icon trash ml-3"
                                            :user-id="user.id"
                                            :name="user.first_name + ' ' + user.last_name"
                                            redirect="/users"
                        ></delete-user-button>
                        -->
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import { EventBus } from "../EventBus";
    import ApiService from '../services/ApiService';
    import Moment from 'moment';

    export default {
        name: "SuperAdminChurchList",
        props: {
            //
        },
        data() {
            return {
                organizations: [],
                loading: true,
                sortKey: null
            }
        },
        mounted() {
            let vm = this;
            EventBus.$on('paginate', page => {
                vm.updateListing(page);
            })

            EventBus.$on('sort', sort => {
                vm.sortListing(sort);
            })

            EventBus.$on('filter', filters => {
                vm.filterListing(filters);
            })

            ApiService.getOrganizationList().then(response => {
                this.organizations = response.data;
                this.loading = false;

                delete response.data;

                EventBus.$emit('setPagination', {
                    ...response
                });
            }).catch(error => {
                console.error(error);
            })
        },
        methods: {
            /**
             * @name getAdminName
             * @param {Object} organization
             * @return {string}
             */
            getAdminName(organization) {
                for(let i = 0; i < organization.users.length; i++) {
                    let user = organization.users[i];
                    let role = user.roles ? user.roles[0] : null;

                    if (role && role.id === 3) {
                        return `${user.first_name} ${user.last_name}`;
                    }
                }

                return ''
            },
            editLink(organization) {
                return `/churches/${organization.id}/edit`
            },
            trashLink(user) {
                return `/users/${user.id}`
            },
            /**
             * @name packageBadge
             * @param packageName
             * @return {string}
             */
            packageBadge(packageName) {
                switch (packageName) {
                    case 'gold':
                        return 'orange';
                    case 'platinum':
                        return 'green';
                    default:
                        return 'default';
                }
            },
            updateListing(page) {
                this.loading = true;

                ApiService.getOrganizationList(page).then(response => {
                    this.organizations = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;
                }).catch(error => {
                    console.error(error);
                })
            },
            sortListing(sortKey) {
                this.loading = true;

                ApiService.getOrganizationList(1, sortKey).then(response => {
                    this.organizations = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;

                    delete response.data;

                    EventBus.$emit('setPagination', {
                        ...response
                    });
                }).catch(error => {
                    console.error(error);
                })
            },
            filterListing(filters) {
                this.loading = true;
                EventBus.$emit('resetSort');

                ApiService.getOrganizationList(1, null, filters).then(response => {
                    this.organizations = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;

                    delete response.data;

                    EventBus.$emit('setPagination', {
                        ...response
                    });
                }).catch(error => {
                    console.error(error);
                })
            }
        }
    }
</script>

<style scoped>

</style>
