<template>
    <div class="row">
        <div class="col-12">
            <div class="progress" style="height: 28px;">
                <div class="progress-bar progress-bar-striped bg-primary" role="progressbar"
                     :style="{'width': progress() + '%'}"
                     :aria-valuenow="progress()"
                     aria-valuemin="0"
                     :aria-valuemax="progress()"></div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <p class="text-lighter mt-3"><small>{{ this.completedText() }}</small></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AssessmentProgress",
        props: {
            questions: {
                type: Number,
                default() {
                    return 0
                }
            },
            complete: {
                type: Number,
                default() {
                    return 0
                }
            }
        },
        computed: {
            lang() {
                return window.lang
            },
            completeCapitalized() {
                return this.lang.get('assessment.completed').charAt(0).toUpperCase() + this.lang.get('assessment.completed').substr(1)
            }
        },
        methods: {
            progress() {
                return Math.ceil(this.complete / this.questions * 100);
            },
            completedText() {
                let lg = this.lang;

                return `${this.completeCapitalized} ${this.complete} ${lg.get('assessment.of')} ${this.questions} ${lg.get('assessment.questions')}`;
            }
        }
    }
</script>

<style scoped>

</style>