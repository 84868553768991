<template>
    <div class="row border-top my-2 my-lg-4">
        <div class="col-12 pl-0">
            <div class="card-body">
                <div class="row d-md-flex py-3">
                    <div class="col-12">
                        <slot></slot>
                    </div>
                    <div class="col-12">
                        <div class="row mt-4">
                            <div class="col-12 col-md-6 col-lg-4" v-for="(option, index) of options">
                                <div class="form-group form-check mb-2">
                                    <input type="checkbox" :id="optionId(option, index)" :name="optionId(option, index)" :value="option" v-model="interface" class="form-check-input" :disabled="loading">
                                    <label :for="optionId(option, index)" class="form-check-label" :disabled="loading">{{ option }}</label>
                                    <input type="text" :disabled="loading" v-show="optionIsOther(option)" v-model="otherInterface" class="form-input small mt-2">
                                    <input type="text" :disabled="loading" v-show="optionIsOther2(option)" v-model="other2Interface" class="form-input small mt-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AssessmentMultiChoiceQuestion from "./AssessmentMultiChoiceQuestion";

    export default {
        name: "AssessmentCheckboxQuestion",
        extends: AssessmentMultiChoiceQuestion,
        props: {
            options: Array,
            value: Array,
            other: String,
            otherTwo: String,
            uId: Number
        },
        computed: {
            interface: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('update:value', value);
                }
            },
            otherInterface: {
                get() {
                    return this.other
                },
                set(value) {
                    this.$emit('update:other', value);
                }
            },
            other2Interface: {
                get() {
                    return this.otherTwo
                },
                set(value) {
                    this.$emit('update:otherTwo', value);
                }
            }
        },
        methods: {
            optionId(optionString) {
                return `option-${optionString.replace(/\/| |/, '-').toLowerCase()}-${this.uId}`
            },
            optionIsOther(optionString) {
                return optionString.toLowerCase().indexOf('other') >= 0 && optionString.toLowerCase().indexOf('2') < 0
            },
            optionIsOther2(optionString) {
                return optionString.toLowerCase().indexOf('other 2') >= 0
            }
        }
    }
</script>

<style scoped>

</style>
