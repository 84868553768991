<template>
    <div>
        <div class="card overflow-hidden mb-5">
            <form @submit.prevent="submitStep1" v-if="step === 1">
                <div class="form-group row">
                    <div class="col-12">
                        <input type="text" class="form-input large" name="name" required v-model="name" autocomplete="company" placeholder="organization name" aria-label="organization name">
                    </div>
                </div>
                <div class="w-100 mt-4 pt-4 border-top">
                    <h3>Location</h3>
                    <div class="row form-group">
                        <div class="col-12 col-lg-7">
                            <select class="form-input large" id="organization_country"
                                    name="organization_country"
                                    required
                                    v-model="country"
                                    aria-label="country">
                                <option disabled>-- Select a country --</option>
                                <option v-for="country of countries" :key="country">{{ country }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-12 col-lg-7">
                            <input type="text" class="form-input large" name="organization_city" required v-model="city" autocomplete="city" placeholder="city" aria-label="city">
                        </div>
                        <div class="col-12 col-lg-5">
                            <select class="form-input large" id="organization_state"
                                    name="organization_state"
                                    required
                                    v-model="state"
                                    v-if="isUS()"
                                    aria-label="state">
                                <option disabled>-- Select a state --</option>
                                <option v-for="(name, abbreviation) of states" :value="abbreviation" :key="abbreviation">{{ name }}</option>
                            </select>
                            <input type="text"
                                   class="form-input large"
                                   id="organization_state_non_us"
                                   name="organization_state" required
                                   v-model="state"
                                   autocomplete="state"
                                   placeholder="state"
                                   v-if="!isUS()"
                                   aria-label="state">
                        </div>
                    </div>
                </div>
                <div class="mt-5 mx-n5 py-4 px-5 mb-n5" style="background-color: #F5F5F5">
                    <h3>Desired Package</h3>
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <p>Select the desired package that you would like to purchase.</p>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-12 col-lg-8 d-flex align-items-center">
                            <div class="flex-grow-1 mr-2">
                                <select class="form-input large" id="package"
                                        name="package"
                                        required
                                        v-model="currentPackage"
                                        aria-label="package">
                                    <option disabled :value="null">-- Select Package --</option>
                                    <option v-for="pkg of packageList" :value="pkg.name" :key="pkg.name">
                                        {{ pkg.name.charAt(0).toUpperCase() + pkg.name.slice(1) }} - ${{ pkg.price }}
                                    </option>
                                </select>
                            </div>

                            <button type="button" class="button blue circle small" @click.prevent="visible = true">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question" class="svg-inline--fa fa-question icon fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"></path></svg>
                            </button>
                        </div>

                        <div class="col-12 col-lg-4 text-right">
                            <button type="submit" class="button large primary" :disabled="!step1IsValid()" v-html="lang.get('pagination.next')"></button>
                        </div>
                    </div>
                </div>
            </form>
            <div v-if="step === 2">
                <div v-if="!success">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center">
                            <div class="text-uppercase text-gray-3 font-size-small">Price</div>
                            <div class="font-weight-semi-bold text-uppercase text-gray-5 font-size-largest mx-3">${{ getPrice() }}</div>

                            <div class="badge blue" v-if="currentPackage === 'silver'">{{ lang.get('packages.silver') }}</div>
                            <div class="badge orange" v-if="currentPackage === 'gold'">{{ lang.get('packages.gold') }}</div>
                            <div class="badge green" v-if="currentPackage === 'platinum'">{{ lang.get('packages.platinum') }}</div>
                        </div>
                    </div>
                    <sign-up-errors class="my-3" :errors="paymentErrors"></sign-up-errors>

                    <sign-up-errors class="my-3" :errors="couponErrors"></sign-up-errors>

                    <form @submit.prevent="applyCoupon" class="row form-group mt-3" v-if="!discountedPrice">
                        <div class="col-8">
                            <input type="text" class="form-input" v-model="promoCode" placeholder="Promo Code" :disabled="thinking || promoLoading" required />
                        </div>
                        <div class="col-4">
                            <button type="submit" class="button block" :disabled="thinking || promoLoading">Apply</button>
                        </div>
                    </form>
                    <div class="bg-light border-radius-3 p-3 my-3" v-if="discountedPrice">
                        <p class="mb-0">Coupon code has been applied.</p>
                    </div>
                    <div class="my-4 border-bottom"></div>
                    <form @submit.prevent="submitPayment">
                        <div class="row form-group">
                            <div class="col-8">
                                <input type="text" class="form-input card-number" placeholder="card number" v-model="card.card_number" :disabled="thinking" required />
                            </div>
                            <div class="col-4">
                                <input type="text" class="form-input" placeholder="cvv" v-model="card.cvv" :disabled="thinking" required />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-6">
                                <input type="text" class="form-input" placeholder="MM" v-model="card.exp_month" :disabled="thinking" required maxlength="2" />
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-input" placeholder="YYYY" v-model="card.exp_year" :disabled="thinking" required maxlength="4" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <button @click="goBack" type="button" class="button w-icon secondary" :disabled="thinking">
                                    <span class="gps-icon arrow-left gray"></span>
                                    Back
                                </button>
                            </div>
                            <div class="col-6 text-right">
                                <button type="submit" class="button large primary" :disabled="thinking || !step2IsValid()">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="text-center" v-if="success">
                    <h3 class="h1 text-normal-case" style="text-transform: none !important;">Thank you!</h3>
                    <p class="text-lighter">Your account has been successfully upgraded. You should be receiving an email confirmation shortly.</p>
                    <p class="text-lighter">Click the button below to view your new content.</p>
                    <div class="mt-4 text-center">
                        <a href="/program-resources" class="button primary">View</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" :class="{'visible' : visible}">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col">
                        <div class="card thin pt-5">
                            <div class="row divided">
                                <div class="col-12 col-lg-4">
                                    <div class="text-center mb-3">
                                        <h3 class="text-gray-5 text-uppercase font-size-large mb-4" style="letter-spacing: .2rem">{{ packageList[0].name }} Package</h3>
                                        <h4 class="text-uppercase text-gray-3 mb-0">Price</h4>
                                        <div class="font-display package-price">${{ packageList[0].price }}</div>
                                    </div>
                                    <ul class="check primary">
                                        <li class="text-gray-4">Downloadable Sermon Outlines</li>
                                        <li class="text-gray-4">Downloadable Small Group Materials</li>
                                        <li class="text-gray-4">Downloadable Small Group Director Kit</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="text-center mb-3">
                                        <h3 class="text-gray-5 text-uppercase font-size-large mb-4" style="letter-spacing: .2rem">{{ packageList[1].name }} Package</h3>
                                        <h4 class="text-uppercase text-gray-3 mb-0">Price</h4>
                                        <div class="font-display package-price">${{ packageList[1].price }}</div>
                                    </div>
                                    <ul class="check danger">
                                        <li class="text-gray-4">All Elements of Silver Level</li>
                                        <li class="text-gray-4">One 30-minute Video Coaching Session</li>
                                        <li class="text-gray-4">Four 30-minute Video Coaching Sessions</li>
                                        <li class="text-gray-4">Downloadable Coach Development Kit</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="text-center mb-3">
                                        <h3 class="text-gray-5 text-uppercase font-size-large mb-4" style="letter-spacing: .2rem">{{ packageList[2].name }} Package</h3>
                                        <h4 class="text-uppercase text-gray-3 mb-0">Price</h4>
                                        <div class="font-display package-price">${{ packageList[2].price }}</div>
                                    </div>
                                    <ul class="check success">
                                        <li class="text-gray-4">All Elements of Silver & Gold Levels</li>
                                        <li class="text-gray-4">2 Years of Admin Functionality</li>
                                        <li class="text-gray-4">Four 30-minute Video Coaching Sessions</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col text-center">
                                    <button type="button" class="button large" @click.prevent="visible = false">Dismiss</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PaymentInformation from "../mixins/PaymentInformation";
    import SignUpErrors from "./SignUpErrors";
    import ApiService from "../services/ApiService";
    import Cleave from "cleave.js";
    export default {
        name: "BasicAccountUpgrade",
        mixins: [
            PaymentInformation,
        ],
        components: {
            SignUpErrors,
        },
        props: {
            states: Object,
            countries: Array,
        },
        data() {
            return {
                step: 1,
                name: '',
                city: '',
                state: 'AL',
                country: 'United States',
                currentPackage: 'silver',
                visible: false,
                card: {
                    card_number: '',
                    cvv: '',
                    exp_month: '',
                    exp_year: '',
                },
                paymentErrors: [],
                success: false,
            }
        },
        watch: {
            country(newValue) {
                if (newValue !== 'United States') {
                    this.state = '';
                } else {
                    this.state = 'AL';
                }
            }
        },
        methods: {
            isUS() {
                return this.country === 'United States'
            },
            step1IsValid() {
                return this.name.length > 2 &&
                    this.city.length > 2 &&
                    this.state.length >= 2 &&
                    this.country.length > 1 &&
                    this.currentPackage;
            },
            step2IsValid() {
                return this.card.card_number.length >= 10 &&
                    this.card.exp_month.length === 2 &&
                    this.card.exp_year.length === 4 &&
                    this.card.cvv.length >= 2;
            },
            goBack() {
                this.step = 1;
            },
            submitStep1() {
                if (this.step1IsValid()) {
                    this.step = 2;
                    this.$nextTick(() => {
                        new Cleave('.card-number', {
                            creditCard: true
                        });
                    })
                }
            },
            submitPayment() {
                this.couponErrors = [];
                this.paymentErrors = [];
                this.thinking = true;

                ApiService.upgradeAccount(
                    this.getPrice() * 100,
                    this.currentPackage,
                    this.name,
                    this.city,
                    this.state,
                    this.country,
                    this.card.card_number,
                    this.card.exp_month,
                    this.card.exp_year,
                    this.card.cvv
                ).then(response => {
                    this.success = true;
                }).catch(error => {
                    let errors = error.errors;

                    if (typeof errors === 'string') {
                        this.paymentErrors = [ errors ];
                    } else {
                        this.paymentErrors = errors;
                    }

                    this.thinking = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>
