<template>
    <loader v-if="loading" :spinner="true" theme="white" size="large"></loader>
    <div class="d-flex p-5 justify-content-center align-items-center align-content-center" v-else-if="!loading && users.length === 0">
        <div class="bg-gray-1 p-5 border-radius-2" v-if="signUpLink">
            <h3 v-text="lang.get('dashboard.its-a-little-quiet-in-here')"></h3>
            <p v-text="lang.get('dashboard.share-the-link-with-your-church')"></p>
            <div class="form-group">
                <input type="text" class="form-input" :value="signUpLink" v-click-to-copy readonly>
            </div>
        </div>
    </div>
    <table class="table" v-else>
        <thead>
        <tr>
            <th v-text="lang.get('forms.name')"></th>
            <th v-text="lang.get('forms.last-assessment')"></th>
            <th v-text="lang.get('forms.gifts')"></th>
            <th v-text="lang.get('forms.passion')"></th>
            <th></th>
        </tr>
        </thead>
        <tbody>
            <tr v-for="user of users">
                <td class="vertical-align-middle">{{ user.first_name }} {{ user.last_name }}</td>
                <td class="vertical-align-middle">
                    {{ firstAssessmentDate(user.assessment_results) }}
                </td>
                <td class="vertical-align-middle">
                    <span class="badge orange small ml-2" v-for="gift of gifts(user.assessment_results)">{{ gift }}</span>
                </td>
                <td class="vertical-align-middle">
                    <span class="badge green small" v-if="passion(user.assessment_results)">{{ passion(user.assessment_results) }}</span>
                </td>
                <td class="vertical-align-middle">
                    <div class="d-flex align-items-center">
                        <a :href="profileLink(user)"
                           class="button thin" v-text="lang.get('auth.profile')"></a>
                        <delete-user-button class="icon trash ml-3"
                                            :user-id="user.id"
                                            v-if="userId !== user.id"
                                            :name="user.first_name + ' ' + user.last_name"
                                            redirect="/user"
                        ></delete-user-button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import { EventBus } from "../EventBus";
    import ApiService from '../services/ApiService';
    import Moment from 'moment';

    export default {
        name: "ChurchAdminUserList",
        props: {
            organization: {
                type: Object,
                required: true
            },
            signUpLink: String,
            userId: Number,
        },
        data() {
            return {
                users: [],
                loading: true,
                sortKey: null,
                searchTerm: ''
            }
        },
        mounted() {
            let vm = this;

            EventBus.$on('paginate', page => {
                if (this.searchTerm && this.searchTerm.length > 0) {
                    // vm.searchListing(this.searchTerm, page);
                } else if (this.sortKey && this.sortKey.length > 0) {
                    vm.sortListing(this.sortKey, page);
                } else {
                    vm.updateListing(page);
                }
            })

            EventBus.$on('sort', sort => {
                vm.sortListing(sort);
            })

            EventBus.$on('filter', filters => {
                vm.filterListing(filters);
            })

            ApiService.getOrganizationUsers(this.organization.id).then(response => {
                this.users = response.data;
                this.loading = false;

                delete response.data;

                EventBus.$emit('setPagination', {
                    ...response
                });
            }).catch(error => {
                console.error(error);
            })
        },
        computed: {
            lang() {
                return window.lang;
            }
        },
        methods: {
            firstAssessmentDate(assessmentArray) {
                if (assessmentArray && assessmentArray.length > 0) {
                    let date = assessmentArray[0].created_at;
                    let moment = Moment(date);

                    return `${moment.format('MM-DD-Y')}`;
                }
                return '';
            },
            gifts(assessmentArray) {
                let array = [];
                if (assessmentArray && assessmentArray.length > 0) {
                    let first = assessmentArray[0];

                    array.push(first.gift1.locale_short_code)
                    array.push(first.gift2.locale_short_code)
                }

                return array;
            },
            passion(assessmentArray) {
                if (assessmentArray && assessmentArray.length > 0) {
                    let first = assessmentArray[0];
                    return first.passion1.locale_name;
                }
                return null;
            },
            profileLink(user) {
                return `/user/${user.id}`
            },
            trashLink(user) {
                return `/users/${user.id}`
            },
            updateListing(page) {
                this.loading = true;

                ApiService.getOrganizationUsers(this.organization.id, page).then(response => {
                    this.users = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;
                }).catch(error => {
                    console.error(error);
                })
            },
            /**
             * @name sortListing
             * @param {string} sortKey
             * @param {number} page
             */
            sortListing(sortKey, page = 1) {
                this.loading = true;

                ApiService.getOrganizationUsers(this.organization.id, page, sortKey).then(response => {
                    this.users = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;

                    delete response.data;

                    EventBus.$emit('setPagination', {
                        ...response
                    });
                }).catch(error => {
                    console.error(error);
                })
            },
            filterListing(filters) {
                this.loading = true;
                EventBus.$emit('resetSort');

                ApiService.getOrganizationUsers(this.organization.id, 1, null, filters).then(response => {
                    this.users = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;

                    delete response.data;

                    EventBus.$emit('setPagination', {
                        ...response
                    });
                }).catch(error => {
                    console.error(error);
                })
            }
        }
    }
</script>

<style scoped>

</style>
