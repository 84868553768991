export default {
    bind(el, binding) {
        el.addEventListener('blur', () => {
            if (el.value && el.value.length > 0) {
                el.classList.add('has-content')
            } else {
                el.classList.remove('has-content')
            }
        });
    }
}