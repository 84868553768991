<template>
    <div>
        <h2 class="text-uppercase font-weight-bold">{{ lang.get('assessment.gifts') }}</h2>
        <h3>{{ lang.get('assessment.your-spiritual-gifts') }}</h3>

        <div class="row pt-3 pb-3 border-top" v-for="(gift, index) of gifts">
            <div class="col-12 mb-3 mb-lg-0 col-lg-3">
                <div class="badge bold" :class="getColor(index + 1)">{{ lang.get(gift.name) }}</div>
            </div>
            <div class="col-12 col-lg-7">
                <p class="mb-lg-0">{{ lang.get(gift.description) }}</p>
            </div>
            <div class="col-12 col-lg-2 text-lg-right">
                <span :class="`text-${getColor(index + 1)}`">{{ lang.get('assessment.score') }}: {{ gift.points }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GiftsResults",
        props: {
            gifts: Array
        },
        computed: {
            lang() {
                return window.lang
            }
        },
        methods: {
            getColor(iteratoion) {
                let swatch = '';
                switch (iteratoion % 4) {
                    case 1:
                        swatch = 'green';
                        break;
                    case 2:
                        swatch = 'blue';
                        break;
                    case 3:
                        swatch = 'orange';
                        break;
                    default:
                        swatch = 'purple';
                }

                return swatch;
            }
        }
    }
</script>

<style scoped>

</style>