<template>
    <div class="alert alert-danger" role="alert" v-if="errors.length > 0">
        <h3 class="text-gray-5">We found the following errors:</h3>
        <ul class="text-gray-5">
            <li v-for="error of errors">{{ error }}</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "SignUpErrors",
        props: {
            errors: {
                type: Array,
                default() {
                    return []
                }
            }
        }
    }
</script>

<style scoped>

</style>
