<template>
    <span class="invalid-feedback" role="alert">
        <em><slot></slot></em>
    </span>
</template>

<script>
    export default {
        name: "ValidationError"
    }
</script>

<style scoped>

</style>
