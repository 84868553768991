<template>
    <div class="no-gutters">
        <div class="row border-bottom my-2 my-lg-4">
            <div class="col-12 pl-0">
                <div class="card-body">
                    <h2 class="text-orange">
                        <slot name="title"></slot>
                    </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 px-0">
                <div class="card-body">
                    <slot></slot>
                </div>
            </div>
        </div>

        <assessment-question-page-footer @back="$emit('back')"
                                         @next="$emit('next')"
                                         :go-back="goBack"
                                         :loading="loading"></assessment-question-page-footer>
    </div>
</template>

<script>
    import AssessmentWelcomePage from "./AssessmentWelcomePage";
    import AssessmentQuestionPageFooter from "./AssessmentQuestionPageFooter";

    export default {
        name: "AssessmentContentPage",
        extends: AssessmentWelcomePage,
        components: {
            AssessmentQuestionPageFooter
        },
        props: {
            goBack: {
                type: Boolean,
                default() {
                    return true;
                }
            }
        }
    }
</script>

<style scoped>

</style>