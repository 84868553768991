<template>
    <metric-card>
        <vue-frappe
            v-if="!loading"
            :id="id"
            :labels="labels"
            type="bar"
            title="Monthly Active Users"
            :height="height"
            :valuesOverPoints="true"
            :tooltipOptions="tooltips"
            :colors="colors"
            :dataSets="graphData">
        </vue-frappe>
        <loader :spinner="true" theme="white" v-else></loader>
    </metric-card>
</template>

<script>
    import MetricMixin from "../../mixins/MetricMixin";

    export default {
        name: "TotalUsersMetric",
        mixins: [ MetricMixin ],
        data() {
            return {
                labels: []
            }
        },
        methods: {
            /**
             * @name updateChartData
             * @param {string} start
             * @param {string} end
             */
            updateChartData(start, end) {
                return new Promise((resolve) => {
                    let vm = this;

                    vm.loading = true;

                    this.axiosProvider.get('users', {
                        params: {
                            //
                        },
                    }).then(response => {
                        this.loading = false;
                        this.labels = [];
                        let values = response.data;
                        let metricValues = [];

                        for(let i = 0; i < values.length; i++) {
                            this.labels.push(values[i].key);
                            metricValues.push(values[i].metrics);
                        }

                        this.graphData = [
                            {
                                name: 'Monthly Active Users',
                                values: metricValues
                            }
                        ];

                        return resolve();
                    }).catch(error => {
                        console.error(error.response);

                        return resolve();
                    });
                })
            }
        }
    }
</script>

<style scoped>

</style>
