<template>
    <div class="user-utility py-3 py-lg-4 px-3 px-lg-3">
        <div class="mb-3" v-if="!switchOrder">
            <div class="row no-gutters">
                <div class="col-12 text-left">
                    <a href="#" @click.prevent="switchLocale">{{ lang.get('dashboard.in-language') }}</a>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
            <div class="user">
                <small>{{ salutation }},</small>
                <slot></slot>
            </div>
            <fly-out :location="flyOutPosition">
                <template #button>
                    <button class="menu-button plain">
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                    </button>
                </template>

                <slot name="content"></slot>
            </fly-out>
        </div>
        <div class="mt-3" v-if="switchOrder">
            <div class="row no-gutters">
                <div class="col-12 text-right">
                    <a href="#" @click.prevent="switchLocale">{{ lang.get('dashboard.in-language') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'SidebarUtility',
        props: {
            flyOutPosition: {
                type: String,
                default() {
                    return 'top'
                }
            },
            locale: {
                type: String,
                default: 'en'
            },
            switchOrder: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                salutation: ''
            }
        },
        mounted() {
            let today = new Date()
            let curHr = today.getHours()

            if (curHr < 12) {
                this.salutation = this.lang.get('toasts.good-morning')
            } else if (curHr < 18) {
                this.salutation = this.lang.get('toasts.good-afternoon')
            } else {
                this.salutation = this.lang.get('toasts.good-evening')
            }
        },
        computed: {
            lang() {
                return window.lang
            }
        },
        methods: {
            switchLocale() {
                switch (this.locale) {
                    case 'es':
                        window.location = '/update-locale?locale=en'
                        break;
                    default:
                        window.location = '/update-locale?locale=es'
                }
            }
        }
    }
</script>
