<template>
    <div class="no-gutters" v-if="questionsToAnswer.length > 0">
        <div class="row my-2 my-lg-4">
            <div class="col-12 pl-0">
                <div class="card-body">
                    <h2 class="text-capitalize">{{ currentHeader }}</h2>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 pl-0">
                <div class="card-body" v-if="this.answers.length > maxAnswers">
                    <alert class="alert-danger" v-text="lang.get('errors.please-select-only-choices', { 'number': maxAnswers })"></alert>
                </div>
            </div>
        </div>

        <assessment-checkbox-question v-for="(question) of questionsToAnswer"
                                      :question-id="question.id"
                                      :other.sync="otherInterface"
                                      :other-two.sync="other2Interface"
                                      :value.sync="answers"
                                      :loading="loading"
                                      :options="options"
                                      :u-id="id"
                                      :key="question.id">
            {{ parseQuestion(question) }}
        </assessment-checkbox-question>

        <assessment-question-page-footer :loading="loading || !canProgress()" @back="back" :go-back="goBack" @next="next"></assessment-question-page-footer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { EventBus } from "../EventBus";
    import AssessmentQuestionPageFooter from './AssessmentQuestionPageFooter';
    import AssessmentCheckboxQuestion from './AssessmentCheckboxQuestion';
    import AssessmentMultiChoiceQuestionsPage from "./AssessmentMultiChoiceQuestionsPage";
    import Alert from "./Alert";

    export default {
        name: "AssessmentCheckboxQuestionsPage",
        extends: AssessmentMultiChoiceQuestionsPage,
        components: {
            Alert,
            AssessmentQuestionPageFooter,
            AssessmentCheckboxQuestion,
        },
        props: {
            options: Array,
            other: String,
            otherTwo: String,
            maxAnswers: {
                type: Number,
                default() {
                    return 3
                }
            }
        },
        data() {
            return {
                answers: [],
            }
        },
        computed: {
            otherInterface: {
                get() {
                    return this.other
                },
                set(value) {
                    this.$emit('update:other', value);
                }
            },
            other2Interface: {
                get() {
                    return this.otherTwo
                },
                set(value) {
                    this.$emit('update:otherTwo', value);
                }
            }
        },
        methods: {
            setQuestions(questions, previousAnswers=null) {
                this.questionsToAnswer = questions;
            },

            canProgress() {
                if (this.answers.length === 0 || this.answers.length > this.maxAnswers) {
                    return false;
                }

                return true;
            },

            next() {
                this.otherInterface = '';
                this.other2Interface = '';
                this.$emit('next', this.compileAnswers());
            },

            back() {
                this.otherInterface = '';
                this.other2Interface = '';
                this.$emit('back', this.compileAnswers());
            },

            compileAnswers() {
                let modifiedAnswers = [];

                for (let i = 0; i < this.answers.length; i++) {
                    let target = this.answers[i];

                    let answer = {
                        multiple_choice_answer: target,
                        question_id: this.questionsToAnswer[0].id,
                        text_value: null
                    };

                    if (target.toLowerCase() === 'other' && this.other !== '') {
                        answer.text_value = this.other
                    }
                    if (target.toLowerCase() === 'other 2' && this.otherTwo !== '') {
                        answer.text_value = this.otherTwo
                    }

                    modifiedAnswers.push(answer);
                }

                return modifiedAnswers;
            },
            parseQuestion(question) {
                switch (this.lang.locale) {
                    case 'es':
                        return question.question_es;
                    default:
                        return question.question;
                }
            }
        }
    }
</script>

<style scoped>

</style>
