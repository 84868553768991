<template>
    <metric-card>
        <vue-frappe
            v-if="!loading"
            :id="id"
            :labels="labels"
            type="bar"
            title="Package Breakdown"
            :height="height"
            :valuesOverPoints="true"
            :tooltipOptions="tooltips"
            :colors="colors"
            :dataSets="graphData">
        </vue-frappe>
        <loader :spinner="true" theme="white" v-else></loader>
    </metric-card>
</template>

<script>
    import MetricMixin from "../../mixins/MetricMixin";

    export default {
        name: "TotalUsersMetric",
        mixins: [ MetricMixin ],
        data() {
            return {
                labels: []
            }
        },
        computed: {
            colors() {
                return [
                    '#92A3AC',
                    '#FF5A00',
                    '#9AD100',
                ];
            },
        },
        methods: {
            /**
             * @name updateChartData
             * @param {string} start
             * @param {string} end
             */
            updateChartData(start, end) {
                return new Promise((resolve) => {
                    let vm = this;

                    vm.loading = true;

                    this.axiosProvider.get('packages', {
                        params: {
                            //
                        },
                    }).then(response => {
                        this.loading = false;
                        this.labels = [];
                        let values = response.data;
                        let silver = [];
                        let gold = [];
                        let platinum = [];

                        for(let i = 0; i < values.length; i++) {
                            let target = values[i];
                            this.labels.push(target.key);
                            silver.push(target.metrics.silver);
                            gold.push(target.metrics.gold);
                            platinum.push(target.metrics.platinum);
                        }

                        this.graphData = [
                            {
                                name: 'Silver',
                                chartType: 'bar',
                                values: silver
                            },
                            {
                                name: 'Gold',
                                chartType: 'bar',
                                values: gold
                            },
                            {
                                name: 'Platinum',
                                chartType: 'bar',
                                values: platinum
                            },
                        ];

                        return resolve();
                    }).catch(error => {
                        console.error(error.response);

                        return resolve();
                    });
                })
            }
        }
    }
</script>

<style scoped>

</style>
