import { render, staticRenderFns } from "./GiftPassionFilter.vue?vue&type=template&id=d6382da6&scoped=true&"
import script from "./GiftPassionFilter.vue?vue&type=script&lang=js&"
export * from "./GiftPassionFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6382da6",
  null
  
)

export default component.exports