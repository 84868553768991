<template>
    <div class="row border-top my-2 my-lg-4">
        <div class="col-12 pl-0">
            <div class="card-body">
                <div class="row d-md-flex py-3">
                    <div class="col-12 col-lg-5 mb-3 mb-lg-0">
                        <slot></slot>
                    </div>
                    <div class="col-12 col-lg-7 d-lg-flex">
                        <div class="d-flex flex-row justify-content-center justify-content-lg-start align-items-center">
                            <p class="text-lighter font-size-small mb-0 d-none d-md-block mr-md-2" v-text="lang.get('assessment.almost-never')"></p>
                            <button class="button circle mx-2"
                                    :class="{'success' : isActive(answer)}"
                                    @click="select(answer)"
                                    :disabled="loading"
                                    v-for="answer of answerList">{{ answer }}</button>
                            <p class="text-lighter font-size-small mb-0 d-none d-md-block ml-md-2" v-text="lang.get('assessment.almost-always')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AssessmentMultiChoiceQuestion",
        props: {
            questionId: Number,
            value: Number,
            loading: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        computed: {
            lang() {
                return window.lang;
            },
            answerList() {
                return [1, 2, 3, 4, 5]
            },
            interface: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('update:value', value);
                }
            }
        },
        methods: {
            isActive(number) {
                if (number && this.interface) {
                    return number.toString() === this.interface.toString();
                }
            },
            select(number) {
                this.interface = number;
            }
        }
    }
</script>

<style scoped>

</style>
