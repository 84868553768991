export default {
    bind(el, binding, vnode) {
        let lastValue = null;

        let buildNumber = () => {
            setTimeout(() => {

                let originalValue = el.value;
                if (originalValue === lastValue) {
                    return;
                }

                let selectionStart = el.selectionStart,
                    selectionEnd = el.selectionEnd;
                let beforeCursor = originalValue.substring(0, selectionStart);
                let nonDigits = beforeCursor.replace(/\d/g, "");
                selectionStart -= nonDigits.length;
                selectionEnd -= nonDigits.length;

                let digitsOnly = originalValue.replace(/\D/g, "");
                let digits = digitsOnly.split("");

                if (digits.length > 3) {
                    digits.splice(3, 0, "-");

                    if (selectionStart > 3) {
                        selectionStart++;
                    }
                    if (selectionEnd > 3) {
                        selectionEnd++;
                    }
                }

                if (digits.length > 7) {
                    digits.splice(7, 0, "-");

                    if (selectionStart > 7) {
                        selectionStart++;
                    }
                    if (selectionEnd > 7) {
                        selectionEnd++;
                    }
                }

                // prevents numbers that are longer than valid numbers
                if (digits.length > 12) {
                    digits.length = 12;
                }
                lastValue = digits.join("");
                let event = new Event('input', {bubbles: true});
                el.value = lastValue;
                el.dispatchEvent(event);

                el.selectionStart = selectionStart;
                el.selectionEnd = selectionEnd;
            });
        }

        el.addEventListener('keydown', buildNumber);
        el.addEventListener('blur', buildNumber);
    }
}