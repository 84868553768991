import Axios from 'axios';

const axios = Axios.create({
    //...
});

/**
 * @name ApiService
 * @description - A service/factory that will handle all Ajax features
 */
export default {

    /**
     * @name serverErrorCatch
     * @description - Performs a catch if the server generates a 500 error and provides a valid error.
     * @param {Object} requestData - The data returned from the http request.
     * @return {boolean}
     */
    serverErrorCatch(requestData) {
        if (requestData.status === 500) {
            // ToastService.error("We have encountered a problem. Please report this error to your sales rep at sizing@schierproducts.com.");
            // return true;
        }
    },

    /**
     * @name assessmentNextPage
     * @description - Creates a new configuration.
     * @param {number} assessmentId - Assessment id
     * @param {number} offset - The project information form data.
     * @param {number} type - The type id to indicate if the app wants gifts, passions, or stories
     * @param {Array<Object>} answers - Answers to be updated
     * @return {Promise<Object[]>}
     */
    assessmentNextPage(assessmentId, offset, answers=[], type=1) {
        return new Promise((resolve, reject) => {
            axios.put(`/assessment/${assessmentId}`, {
                offset,
                answers,
                type
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name assessmentNextPage
     * @description - Creates a new configuration.
     * @param {number} assessmentId - Assessment id
     * @param {number} offset - The project information form data.
     * @param {Array<Object>} answers - Answers to be updated
     * @return {Promise<Object[]>}
     */
    assessmentPreviousPage(assessmentId, offset, answers=[]) {
        return new Promise((resolve, reject) => {
            axios.put(`/assessment/${assessmentId}`, {
                offset,
                answers
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name assessmentNextPage
     * @description - Creates a new configuration.
     * @param {number} assessmentId - Assessment id
     * @return {Promise<Object[]>}
     */
    assessmentInitialQuestions(assessmentId) {
        return new Promise((resolve, reject) => {
            axios.get(`/assessment/${assessmentId}/initial`).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name getOrganizationUsers
     * @description - Gets users based on the organization
     * @param {number} organizationId - Assessment id
     * @param {number} page
     * @param {string|null} sortKey
     * @param {array|null} filterArray
     * @return {Promise<Object[]>}
     */
    getOrganizationUsers(organizationId, page = 1, sortKey = null, filterArray=[]) {
        let params = {
            page: page,
        };

        if (sortKey) {
            params.sort = sortKey
        }
        if (filterArray) {
            params.filters = filterArray
        }

        return new Promise((resolve, reject) => {
            axios.get(`/users/${organizationId}`, {
                params
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name toggleUserRole
     * @param {number} userId
     * @param {number} organizationId
     * @param {string} role
     * @return {Promise<any>}
     */
    toggleUserRole(userId, organizationId, role) {
        return new Promise((resolve, reject) => {
            axios.put(`/user/${userId}/role`, {
                organization: organizationId,
                role,
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name toggleUserRole
     * @param {number} userId
     * @return {Promise<any>}
     */
    deleteUser(userId) {
        return new Promise((resolve, reject) => {
            axios.delete(`/user/${userId}`).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name validateEmail
     * @param {string} email
     * @return {Promise<any>}
     */
    validateEmail(email) {
        return new Promise((resolve, reject) => {
            axios.post(`/validate-email`, {
                email
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name validateRegistrationContent
     * @param {Object} formData
     * @return {Promise<any>}
     */
    validateRegistrationContent(formData) {
        return new Promise((resolve, reject) => {
            axios.post(`/validate-registration`, formData).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name retrieveCoupon
     * @param {number} coupon
     * @param {number} price
     * @return {Promise<any>}
     */
    retrieveCoupon(coupon, price) {
        return new Promise((resolve, reject) => {
            axios.post(`/payment/coupon`, {
                coupon,
                current_cost: price,
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     *
     * @param {number} price
     * @param {string} pkg
     * @param {string} first_name
     * @param {string} last_name
     * @param {string} email
     * @param {string} password
     * @param {string} password_confirmation
     * @param {string} phone_number
     * @param {string} church_name
     * @param {string} city
     * @param {string} state
     * @param {string} country
     * @param {string} card_number
     * @param {string} exp_month
     * @param {string} exp_year
     * @param {string} cvv
     * @return {Promise<any>}
     */
    submitOrganizationCreation(price, pkg, first_name, last_name, email, password, password_confirmation, phone_number, church_name, city, state, country, card_number, exp_month, exp_year, cvv) {
        return new Promise((resolve, reject) => {
            axios.post(`/organization`, {
                price,
                package: pkg,
                first_name,
                last_name,
                email,
                password,
                password_confirmation,
                phone_number,
                church_name,
                city,
                state,
                country,
                card_number,
                exp_month,
                exp_year,
                cvv,
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name getOrganizationList
     * @description - Gets users based on the organization
     * @param {number} page
     * @param {string|null} sortKey
     * @param {array|null} filterArray
     * @return {Promise<Object[]>}
     */
    getOrganizationList(page = 1, sortKey = null, filterArray=[]) {
        let params = {
            page: page,
        };

        if (sortKey) {
            params.sort = sortKey
        }
        if (filterArray) {
            params.filters = filterArray
        }

        return new Promise((resolve, reject) => {
            axios.get(`/churches/list`, {
                params
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name getUserList
     * @description - Gets users
     * @param {number} page
     * @param {string|null} sortKey
     * @return {Promise<Object[]>}
     */
    getUserList(page = 1, sortKey = null) {
        let params = {
            page: page,
        };

        if (sortKey) {
            params.sort = sortKey
        }

        return new Promise((resolve, reject) => {
            axios.get(`/users/list`, {
                params
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name userListSearch
     * @description - Gets users by search tearm
     * @param {number} page
     * @param {string|null} searchTerm
     * @return {Promise<Object[]>}
     */
    userListSearch(page = 1, searchTerm=null) {
        let params = {
            page: page,
        };
        if (searchTerm) {
            params.search = searchTerm
        }

        return new Promise((resolve, reject) => {
            axios.get(`/users/search`, {
                params
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name searchChurches
     * @description - Gets churches by search tearm
     * @param {string} searchTerm
     * @param {number|null} page
     * @return {Promise<Object[]>}
     */
    searchChurches(searchTerm, page=null) {
        let params = {
            page: page,
            search: searchTerm
        };

        return new Promise((resolve, reject) => {
            axios.get(`/churches/search`, {
                params
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name assignUsersToChurch
     * @description - Gets churches by search tearm
     * @param {array} users
     * @param {number} organizationId
     * @return {Promise<Object[]>}
     */
    assignUsersToChurch(users, organizationId) {
        return new Promise((resolve, reject) => {
            axios.post(`/churches/assign`, {
                users,
                organizationId,
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    },

    /**
     * @name upgradeAccount
     * @description - Upgrade a basic account to a paid account
     * @param {Number} price
     * @param {String} pkg
     * @param {String} organization
     * @param {String} city
     * @param {String} state
     * @param {String} country
     * @param {String} cardNumber
     * @param {String} month
     * @param {String} year
     * @param {String} cvv
     * @returns {Promise<String>}
     */
    upgradeAccount(price, pkg, organization, city, state, country, cardNumber, month, year, cvv) {
        return new Promise((resolve, reject) => {
            axios.post(`/account/upgrade`, {
                price,
                'package': pkg,
                organization,
                city,
                state,
                country,
                'card_number': cardNumber,
                'exp_month': month,
                'exp_year': year,
                cvv,
            }).then(success => {
                return resolve(success.data)
            }).catch(error => {
                return reject(error.response.data);
            });
        });
    }
}
