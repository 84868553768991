<template>
    <loader v-if="loading" :spinner="true" theme="white" size="large"></loader>
    <table class="table" v-else>
        <thead>
        <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Church</th>
            <th>Location</th>
            <th>Sign Up Date</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
            <tr v-for="user of users" :key="user.id">
                <td class="vertical-align-middle">{{ user.first_name }} {{ user.last_name }}</td>
                <td class="vertical-align-middle"><a :href="`mailto:${user.email}`">{{ user.email }}</a></td>
                <td class="vertical-align-middle">
                    {{ church(user) }}
                </td>
                <td class="vertical-align-middle">
                    {{ location(user) }}
                </td>
                <td class="vertical-align-middle">
                    {{ createdDate(user.created_at) }}
                </td>
                <td class="vertical-align-middle text-right">
                    <div class="d-flex align-items-center justify-content-end">
                        <a :href="`/user/impersonate?id=${user.id}`" class="button primary thin mr-2">Impersonate</a>
                        <div style="min-width: 35px">
                            <div class="form-check" v-if="user.organizations.length === 0">
                                <input type="checkbox" v-model="selectedUsers" :value="user.id" :id="'user' + user.id" :name="'user' + user.id" class="form-check-input">
                                <label :for="'user' + user.id"  class="form-check-label"></label>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    import { EventBus } from "../EventBus";
    import ApiService from '../services/ApiService';
    import Moment from 'moment';

    export default {
        name: "SuperAdminUserList",
        data() {
            return {
                users: [],
                loading: true,
                sortKey: null,
                selectedUsers: [],
                searchTerm: ''
            }
        },
        mounted() {
            let vm = this;
            EventBus.$on('paginate', page => {
                if (this.searchTerm && this.searchTerm.length > 0) {
                    vm.searchListing(this.searchTerm, page);
                } else if (this.sortKey && this.sortKey.length > 0) {
                    vm.sortListing(this.sortKey, page);
                } else {
                    vm.updateListing(page);
                }
            })

            EventBus.$on('sort', sort => {
                this.sortKey = sort;
                vm.sortListing(sort);
            })

            EventBus.$on('filter', filters => {
                vm.filterListing(filters);
            })

            EventBus.$on('search', searchTerm => {
                this.searchTerm = searchTerm;
                this.sortKey = '';
                vm.searchListing(searchTerm);
            })

            EventBus.$on('clearSearch', () => {
                this.searchTerm = '';
                this.getInitialList();
            })

            EventBus.$on('resetUserMassSelect', () => {
                this.selectedUsers = [];
            });

            this.getInitialList();
        },
        watch: {
            selectedUsers: function(newValue, oldValue) {
                if (newValue.length !== oldValue.length) {
                    EventBus.$emit('userMassSelect', newValue);
                }
            }
        },
        methods: {
            getInitialList() {
                ApiService.getUserList().then(response => {
                    this.users = response.data;
                    this.loading = false;

                    delete response.data;

                    EventBus.$emit('setPagination', {
                        ...response
                    });
                }).catch(error => {
                    console.error(error);
                })
            },
            createdDate(date) {
                if (date) {
                    let moment = Moment(date);

                    return `${moment.format('MM-DD-Y')}`;
                }
            },
            church(user) {
                if (user.organizations && user.organizations.length > 0) {
                    return user.organizations[0].name;
                }
            },
            location(user) {
                if (user.state && user.city) {
                    return `${user.city} ${user.state}`
                }
            },
            updateListing(page) {
                this.loading = true;

                ApiService.getUserList(page).then(response => {
                    this.users = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;
                }).catch(error => {
                    console.error(error);
                })
            },
            sortListing(sortKey, page = 1) {
                this.loading = true;

                ApiService.getUserList(page, sortKey).then(response => {
                    this.users = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;

                    delete response.data;

                    EventBus.$emit('setPagination', {
                        ...response
                    });
                }).catch(error => {
                    console.error(error);
                })
            },
            filterListing(sortKey) {
                this.loading = true;
                EventBus.$emit('resetSort');

                ApiService.getUserList(1, null, sortKet).then(response => {
                    this.users = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;

                    delete response.data;

                    EventBus.$emit('setPagination', {
                        ...response
                    });
                }).catch(error => {
                    console.error(error);
                })
            },
            searchListing(searchTerm, page = 1) {
                this.loading = true;

                ApiService.userListSearch(page, searchTerm).then(response => {
                    this.users = response.data;
                    window.scrollTo(0,0);
                    this.loading = false;

                    delete response.data;

                    console.log(response);

                    EventBus.$emit('setPagination', {
                        ...response
                    });
                }).catch(error => {
                    console.error(error);
                })
            }
        }
    }
</script>

<style scoped>

</style>
