<template>
    <div class="modal" :class="{'visible' : visible}">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-6 col-lg-5">
                    <div class="card thin text-center">
                        <p class="mb-0" v-text="lang.get('dashboard.are-you-sure-you-want-to-delete')"></p>
                        <h3 class="text-primary">{{ name }}</h3>
                        <div class="d-flex flex-row justify-content-center mb-2">
                            <button type="button" @click="hide" :disabled="loading" class="button thin mr-3" v-text="lang.get('dashboard.Cancel')"></button>
                            <button type="button" @click="deleteUser" :disabled="loading" class="button thin orange ml-3" v-text="lang.get('dashboard.yes-delete')"></button>
                        </div>
                        <p class="mb-0"><small v-text="lang.get('dashboard.note-this-action-cannot-be-undone')"></small></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";
    import ApiService from "../services/ApiService";

    export default {
        name: "UserDeleteModal",
        props: {

        },
        data() {
            return {
                visible: false,
                name: '',
                userId: null,
                loading: false,
                redirect: null
            }
        },
        mounted() {
            EventBus.$on('showUserDelete', data => {
                this.name = data.name;
                this.userId = data.userId;
                this.redirect = data.redirect;
                this.visible = true;
            })
        },
        computed: {
            lang() {
                return window.lang;
            }
        },
        methods: {
            hide() {
                this.name = '';
                this.userId = null;
                this.visible = false;
                this.redirect = null;
                this.loading = false;
            },

            deleteUser() {
                this.loading = true;
                ApiService.deleteUser(this.userId).then(() => {
                    if (this.redirect) {
                        window.location = this.redirect;
                    } else {
                        this.hide();
                    }
                }).error(error => {
                    console.error(error);
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
