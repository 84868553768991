<template>
    <div class="d-flex justify-content-center align-items-center ml-3">
        <h4 class="mb-0 mr-3" v-text="lang.get('dashboard.permissions')"></h4>
        <select name="role" id="role" v-model="role" class="form-input" :disabled="loading">
            <option :value="role.id" v-for="role of roles">{{ role.locale_name }}</option>
        </select>
    </div>
</template>

<script>
    import ApiService from "../services/ApiService";

    export default {
        name: "ChurchAdminUserPermissions",
        props: {
            roleId: {
                type: Number,
                required: true
            },
            userId: {
                type: Number,
                required: true
            },
            organizationId: {
                type: Number,
                required: true
            },
            roles: Array
        },
        data() {
            return {
                loading: false,
                role: null,
                done: false
            }
        },
        mounted() {
            this.role = this.roleId;

            setTimeout(() => {
                this.done = true;
            }, 200)
        },
        watch: {
            role: function(newValue, oldValue) {
                if (this.done && newValue !== oldValue) {
                    this.loading = true;

                    ApiService.toggleUserRole(this.userId, this.organizationId, this.role).then(response => {
                        this.loading = false;
                    }).catch(error => {
                        console.error(error)
                    })
                }
            }
        },
        computed: {
            lang() {
                return window.lang;
            }
        }
    }
</script>

<style scoped>

</style>
