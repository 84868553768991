<template>
    <section class="card drawer-card" :class="{'open pb-0': open}">
        <button class="button icon toggle" @click="toggleDrawer">
            <span class="line line-1"></span>
            <span class="line line-2"></span>
        </button>

        <div class="card-header" v-if="hasHeaderSlot">
            <slot name="header"></slot>
        </div>

        <div class="drawer-body no-gutters">
            <slot></slot>
        </div>
    </section>
</template>

<script>
    export default {
        name: "DrawerCard",
        props: {
            startOpen: {
                type: Boolean,
                default() {
                    return false
                }
            },
        },
        data() {
            return {
                open: false
            }
        },
        mounted() {
            if (this.startOpen) {
                this.open = true;
            }
        },
        computed: {
            hasDefaultSlot () {
                return !!this.$slots.default
            },
            hasHeaderSlot () {
                return !!this.$slots['header']
            }
        },
        methods: {
            toggleDrawer() {
                if (this.open) {
                    this.open = false;
                } else {
                    this.open = true;
                }
            }
        }
    }
</script>

<style scoped>

</style>