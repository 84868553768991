export default {
    // english
    'en.assessment': require('../../lang/en/assessment.php'),
    'en.auth': require('../../lang/en/auth.php'),
    'en.dashboard': require('../../lang/en/dashboard.php'),
    'en.errors': require('../../lang/en/errors.php'),
    'en.forms': require('../../lang/en/forms.php'),
    'en.http-statuses': require('../../lang/en/http-statuses.php'),
    'en.packages': require('../../lang/en/packages.php'),
    'en.pagination': require('../../lang/en/pagination.php'),
    'en.passwords': require('../../lang/en/passwords.php'),
    'en.toasts': require('../../lang/en/toasts.php'),
    'en.validation': require('../../lang/en/validation.php'),
    // spanish
    'es.assessment': require('../../lang/es/assessment.php'),
    'es.auth': require('../../lang/es/auth.php'),
    'es.dashboard': require('../../lang/es/dashboard.php'),
    'es.errors': require('../../lang/es/errors.php'),
    'es.forms': require('../../lang/es/forms.php'),
    'es.http-statuses': require('../../lang/es/http-statuses.php'),
    'es.packages': require('../../lang/es/packages.php'),
    'es.pagination': require('../../lang/es/pagination.php'),
    'es.passwords': require('../../lang/es/passwords.php'),
    'es.toasts': require('../../lang/es/toasts.php'),
    'es.validation': require('../../lang/es/validation.php'),
}
