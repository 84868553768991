<template>
    <div class="fly-out" :class="[location]">
        <div class="fly-out-button" @click="toggle" :class="{'active' : visible}">
            <slot name="button">
                <button class="button secondary w-icon right">
                    Select
                    <span class="gps-icon caret"></span>
                </button>
            </slot>
        </div>
        <div class="fly-out-menu" :class="{'active' : visible}" :style="style"  v-click-outside="clickOutside">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FlyOut",
        props: {
            location: {
                type: String,
                default: 'top',
                validator: value => {
                    let possibleValues = ['top', 'bottom'];
                    return possibleValues.indexOf(value) >= 0;
                }
            },
            minWidth: Number
        },
        data() {
            return {
                visible: false,
                timeout: false
            }
        },
        methods: {
            toggle() {
                if (this.visible) {
                    this.visible = false;
                    this.$emit('hide');
                } else {
                    this.visible = true;
                    this.setDelay()
                }
            },

            clickOutside() {
                if (this.visible && !this.timeout) {
                    this.visible = false;
                    this.$emit('hide');
                }
            },

            setDelay() {
                let vm = this;
                this.timeout = true;

                setTimeout(function() {
                    vm.timeout = false;
                }, 200)
            },

            style() {
                if (this.minWidth) {
                    return {'min-width': `${this.minWidth}px`}
                }
            }
        }
    }
</script>

<style scoped>
    .fly-out-button {
        display: inline-block;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    }
</style>