<template>
    <div class="card thin">
        <div class="row">
            <div class="col-12">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MetricCard"
    }
</script>

<style scoped>

</style>
