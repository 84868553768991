<template>
    <div class="d-flex flex-row">
        <sort-filter class="mr-5" :categories="sortList"></sort-filter>
    </div>
</template>

<script>
    export default {
        name: "ChurchAdminUserFilters",
        data() {
            return {
                //
            }
        },
        computed: {
            sortList() {
                return [
                    {key: "name", value: "Church Name"},
                    {key: "package", value: "Package"},
                    {key: "users", value: "# of Users"},
                ]
            }
        },
        methods: {
            //
        }
    }
</script>

<style scoped>

</style>
