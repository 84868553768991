<template>
    <div class="modal" :class="{'visible' : visibleInterface}">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-6 col-lg-5">
                    <div class="card thin text-center">
                        <p class="mb-0">Search and select the church that you would like to add these users to:</p>

                        <div class="row text-left">
                            <div class="col-12">
                                <div class="p-3 border-radius-3 bg-light mt-4">
                                    <div class="row justify-content-center">
                                        <form @submit.prevent="searchChurches" class="col-12 form-group d-flex flex-row">
                                            <input type="text" class="form-input icon search" id="church-name-search" v-model="search" :disabled="assignLoading || loading" placeholder="Church name" />
                                            <button class="button thin flex-shrink-0 ml-2" :disabled="assignLoading || loading || search.length === 0">Search</button>
                                        </form>
                                    </div>
                                    <div v-if="!loading && searchCompleted">
                                        <hr class="my-4">

                                        <div v-if="churches.length > 0">
                                            <div class="row" v-for="church of churches" :key="church.id">
                                                <div class="col-8">
                                                    {{ church.name }}
                                                </div>
                                                <div class="col-4 text-right">
                                                    <button class="tiny button primary" :disabled="assignLoading" @click="assignToChurch(church.id)">Select</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center" v-else>
                                            <p class="font-weight-bold text-lighter">No churches were found.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 text-right">
                                <button type="button" @click="cancel" :disabled="assignLoading" class="button thin">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";
    import ApiService from "../services/ApiService";

    export default {
        name: "AssignToChurchModal",
        props: {
            visible: {
                type: Boolean,
                default() {
                    return false
                }
            },
            users: Array
        },
        data() {
            return {
                search: '',
                churches: [],
                loading: false,
                assignLoading: false,
                redirect: null,
                searchCompleted: false
            }
        },
        computed: {
            visibleInterface: {
                get() {
                    return this.visible
                },
                set(value) {
                    this.$emit('update:visible', value);
                }
            }
        },
        methods: {
            searchChurches() {
                this.loading = true;

                ApiService.searchChurches(this.search).then(response => {
                    this.loading = false;
                    this.searchCompleted = true;
                    this.churches = response;
                }).catch(error => {
                    this.loading = false;
                    this.searchCompleted = true;
                    this.churches = [];
                    console.error(error);
                });
            },
            assignToChurch(churchId) {
                this.assignLoading = true;

                ApiService.assignUsersToChurch(this.users, churchId).then(response => {
                    this.$emit('done');
                }).catch(error => {
                    this.assignLoading = false;
                    console.error(error);
                });
            },
            cancel() {
                this.visibleInterface = false;
                this.searchCompleted = false;
                this.search = '';
                this.churches = [];
            }
        }
    }
</script>

<style scoped>

</style>
