<template>
    <div class="sort-filter">
        <fly-out location="bottom" ref="flyOut">
            <template #button>
                <div class="wrapper">
                    <div class="icon"></div>
                    <div class="label">
                        Sort By: {{ label() }}
                    </div>
                    <div class="caret"></div>
                </div>
            </template>

            <button class="menu-item plain" @click="sortContent(category)" v-for="category of categories">{{ string(category) ? category : category.value }}</button>
        </fly-out>
    </div>
</template>

<script>
    import { EventBus } from '../EventBus';
    import _ from 'lodash';

    export default {
        name: "SortFilter",
        props: {
            categories: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                sort: null
            }
        },
        mounted() {
            EventBus.$on('resetSort', () => {
                if (_.isString(this.categories[0])) {
                    this.sort = this.categories[0]
                } else {
                    this.sort = this.categories[0].key
                }
            });

            if (this.categories && this.categories.length > 0) {
                if (_.isString(this.categories[0])) {
                    this.sort = this.categories[0]
                } else {
                    this.sort = this.categories[0].key
                }
            }
        },
        methods: {
            /**
             * @param {string|Object} value
             * @return {boolean}
             */
            string(value) {
                return _.isString(value)
            },

            /**
             * @return {string|null}
             */
            label() {
                if (this.categories && this.categories.length > 0) {
                    if (_.isString(this.categories[0])) {
                        return this.sort
                    } else {
                        for(let i = 0; i < this.categories.length; i++) {
                            if (this.categories[i].key === this.sort) {
                                return this.categories[i].value;
                            }
                        }
                    }
                }
            },

            sortContent(category) {
                this.$refs.flyOut.visible = false;

                if (this.string(category)) {
                    this.sort = category;
                    this.$emit('sort', category);
                    EventBus.$emit('sort', category);
                } else {
                    this.sort = category.key;
                    this.$emit('sort', category.key)
                    EventBus.$emit('sort', category.key)
                }
            }
        }
    }
</script>

<style scoped>

</style>