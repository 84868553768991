<template>
    <div class="alert" role="alert">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Alert"
    }
</script>

<style scoped>

</style>
