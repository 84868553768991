export default {
    bind(el, binding) {
        el.addEventListener('focus', () => {
            let pTag = document.createElement("small");
            let node = document.createTextNode("URL has been copied");
            pTag.appendChild(node);
            el.parentElement.append(pTag);

            el.select();
            document.execCommand('copy');

            setTimeout(() => {
                let children = el.parentElement.children;

                for(let i = 0; i < children.length; i++) {
                    if (children[i].tagName.toLowerCase() === 'small') {
                        children[i].remove();
                    }
                }
            }, 1500);
        });
    }
}