<template>
    <div class="no-gutters" v-if="questionsToAnswer.length > 0">
        <div class="row my-2 my-lg-4">
            <div class="col-12 pl-0">
                <div class="card-body">
                    <h2 class="text-capitalize">{{ currentHeader }}</h2>
                    <div class="row d-none d-md-flex">
                        <div class="col-4 col-lg-5">
                            <h3 class="text-capitalize text-gray-3 font-display mb-0">{{ lang.get('assessment.statements') }}</h3>
                        </div>
                        <div class="col-8 col-lg-7">
                            <h3 class="text-capitalize text-gray-3 font-display mb-0">{{ lang.get('assessment.answers') }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <assessment-multi-choice-question v-for="(question, index) of questionsToAnswer"
                                          :question-id="question.id"
                                          :value.sync="answersToSubmit[index].numeric_value"
                                          :loading="loading"
                                          :key="question.id">
            {{ parseQuestion(question) }}
        </assessment-multi-choice-question>

        <assessment-question-page-footer :loading="loading"
                                         @back="back"
                                         :go-back="goBack"
                                         @next="next"
                                         :go-forward="canGoForward()"
        ></assessment-question-page-footer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import { EventBus } from "../EventBus";
    import AssessmentQuestionPageFooter from './AssessmentQuestionPageFooter';
    import AssessmentMultiChoiceQuestion from './AssessmentMultiChoiceQuestion';
    import ParseLocaleQuestion from "../mixins/ParseLocaleQuestion";

    export default {
        name: "AssessmentMultiChoiceQuestionsPage",
        components: {
            AssessmentQuestionPageFooter,
            AssessmentMultiChoiceQuestion,
        },
        mixins: [
            ParseLocaleQuestion
        ],
        props: {
            currentHeader: {
                type: String,
                default() {
                    return window.lang.get('assessment.gifts')
                }
            },
            loading: {
                type: Boolean,
                default() {
                    return false
                }
            },
            goBack: {
                type: Boolean,
                default() {
                    return true
                }
            }
        },
        data() {
            return {
                id: null,
                questionsToAnswer: [],
                answersToSubmit: [],
            }
        },
        computed: {
            lang() {
                return window.lang
            }
        },
        mounted() {
            let vm = this;
            this.id = this._uid;
            EventBus.$on('setNewQuestions', data => {
                vm.setQuestions(data.questions, data.previousAnswers);
            })
        },
        methods: {
            setQuestions(questions, previousAnswers=null) {
                this.questionsToAnswer = questions;

                this.answersToSubmit = questions.map(question => {
                    return { question_id: question.id, numeric_value: 0 }
                });

                if (previousAnswers && !_.isEmpty(previousAnswers)) {
                    let vm = this;

                    for(let i = 0; i < this.answersToSubmit.length; i++) {
                        let answerValue = _.find(previousAnswers, function(answer) {
                            return answer.question_id === vm.answersToSubmit[i].question_id;
                        });

                        if (answerValue) {
                            this.answersToSubmit[i].numeric_value = answerValue.numeric_value;
                            this.answersToSubmit[i].id = answerValue.id;
                        }
                    }
                }
            },

            next() {
                this.$emit('next', this.answersToSubmit);

                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            },

            back() {
                let answeredResults = [];
                for(let i = 0; i < this.answersToSubmit.length; i++) {
                    let answer = this.answersToSubmit[i];
                    if (answer.numeric_value > 0) {
                        answeredResults.push(answer);
                    }
                }
                this.$emit('back', answeredResults);
            },

            canGoForward() {
                const currentQuestionType = this.questionsToAnswer[0].question_type_id;
                if (currentQuestionType === 1) {
                    for(let i = 0; i < this.answersToSubmit.length; i++) {
                        if (this.answersToSubmit[i].numeric_value === 0) {
                            return false;
                        }
                    }
                }
                return true;
            },

            parseQuestion(question) {
                switch (this.lang.locale) {
                    case 'es':
                        return question.question_es;
                    default:
                        return question.question;
                }
            },

            parseExample(question) {
                switch (this.lang.locale) {
                    case 'es':
                        return question.default_es;
                    default:
                        return question.default;
                }
            }
        }
    }
</script>

<style scoped>

</style>
