<template>
    <div>

        <transition name="fade">
            <package-selection v-if="showSelection()"
                               :package-list="packageList"
                               @select="packageSelect"
                               :asset-url="assetUrl"
            ></package-selection>
        </transition>
        <transition name="fade">
            <user-registration v-if="showRegistration()"
                               @back="clearPackage"
                               @validated="showPaymentView"
                               :package="package"
                               :form-data.sync="userForm"
                               :states="states"
                               :countries="countries"
                               :package-list="packageList"
            ></user-registration>
        </transition>
        <transition name="fade">
            <payment-information v-if="showPayment()"
                                 @back="clearPayment"
                                 :package-list="packageList"
                                 :payment-data.sync="paymentData"
                                 :package="package"
                                 @submit="submit"
                                 :thinking="thinking"
                                 :errors="paymentErrors"
            ></payment-information>
        </transition>
    </div>
</template>

<script>
    import PackageSelection from './PackageSelection';
    import UserRegistration from './UserRegistration';
    import PaymentInformation from './PaymentInformation';
    import ApiService from "../services/ApiService";

    export default {
        name: "CreateAccount",
        components: {
            UserRegistration,
            PackageSelection,
            PaymentInformation,
        },
        props: {
            states: Array,
            countries: Array,
            packageList: Array,
            environment: {
                type: String,
                default() {
                    return 'production'
                }
            },
            assetUrl: {
                type: String,
                default() {
                    return '/'
                }
            }
        },
        data() {
            return {
                thinking: false,
                package: '',
                userForm: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    phone_number: '',
                    church_name: '',
                    city: '',
                    state: '',
                    country: 'United States',
                },
                paymentData: {
                    card_number: '',
                    exp_month: '',
                    exp_year: '',
                    cvv: '',
                },
                userRegisterVisible: false,
                paymentVisible: false,
                paymentErrors: []
            }
        },
        methods: {
            packageSelect(name) {
                if (name === 'free') {
                    window.location = '/register';
                } else {
                    this.package = name
                    this.userRegisterVisible = true;
                }
            },
            showSelection() {
                return !this.package;
            },
            showRegistration() {
                return this.package && this.userRegisterVisible;
            },
            clearPackage() {
                this.package = null
                this.userRegisterVisible = false;
            },
            showPaymentView() {
                this.userRegisterVisible = false;
                this.paymentVisible = true;
            },
            showPayment() {
                return this.package && !this.userRegisterVisible && this.paymentVisible;
            },
            clearPayment() {
                this.userRegisterVisible = true;
                this.paymentVisible = false;
            },
            submit(submitObject) {
                this.thinking = true;
                this.paymentErrors = [];

                let vm = this;

                ApiService.submitOrganizationCreation(
                    submitObject.price,
                    this.package,
                    this.userForm.first_name,
                    this.userForm.last_name,
                    this.userForm.email,
                    this.userForm.password,
                    this.userForm.password_confirmation,
                    this.userForm.phone_number,
                    this.userForm.church_name,
                    this.userForm.city,
                    this.userForm.state,
                    this.userForm.country,
                    this.paymentData.card_number,
                    this.paymentData.exp_month,
                    this.paymentData.exp_year,
                    this.paymentData.cvv
                ).then(response => {
                    if (this.environment === 'production') {
                        fbq('track', 'Purchase', {
                            value: (submitObject.price / 100),
                            currency: 'USD',
                            package: vm.package,
                        });
                    }

                    if (vm.package === 'silver') {
                        window.location = '/program-resources'
                    } else {
                        window.location = '/user'
                    }
                }).catch(error => {
                    if (error.message) {
                        this.paymentErrors.push(error.message)
                    } else {
                        this.paymentErrors = error.errors;
                    }
                    this.thinking = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>
