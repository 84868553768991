import Vue from "vue";
import Rollbar from 'rollbar';

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = Vue;

import vClickOutside from 'v-click-outside'
import Chart from 'vue2-frappe';

Vue.use(vClickOutside);
Vue.use(Chart);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('sidebar-utility', require('./components/SidebarUtility.vue').default);
Vue.component('fly-out', require('./components/FlyOut.vue').default);
Vue.component('sort-filter', require('./components/SortFilter.vue').default);
Vue.component('create-account', require('./components/CreateAccount.vue').default);
Vue.component('drawer-card', require('./components/DrawerCard.vue').default);
Vue.component('assessment', require('./components/Assessment.vue').default);
Vue.component('church-admin-user-list', require('./components/ChurchAdminUserList.vue').default);
Vue.component('loader', require('./components/Loader.vue').default);
Vue.component('pagination', require('./components/Pagination.vue').default);
Vue.component('church-admin-user-filters', require('./components/ChurchAdminUserFilters.vue').default);
Vue.component('church-admin-user-permissions', require('./components/ChurchAdminUserPermissions.vue').default);
Vue.component('user-delete-modal', require('./components/UserDeleteModal.vue').default);
Vue.component('delete-user-button', require('./components/DeleteUserButton.vue').default);
Vue.component('super-admin-church-list', require('./components/SuperAdminChurchList.vue').default);
Vue.component('super-admin-user-list', require('./components/SuperAdminUserList.vue').default);
Vue.component('super-admin-church-filters', require('./components/SuperAdminChurchFilters.vue').default);
Vue.component('super-admin-church-admin-selector', require('./components/SuperAdminChurchAdminSelector.vue').default);
Vue.component('super-admin-user-filters', require('./components/SuperAdminUserFilters.vue').default);
Vue.component('total-users-metric', require('./components/Metrics/TotalUsersMetric.vue').default);
Vue.component('total-organizations-metric', require('./components/Metrics/TotalOrganizationsMetric.vue').default);
Vue.component('total-packages-metric', require('./components/Metrics/TotalPackagesMetric.vue').default);
Vue.component('total-completed-assessments-metric', require('./components/Metrics/TotalCompletedAssessmentsMetric.vue').default);
Vue.component('total-revenue-metric', require('./components/Metrics/TotalRevenueMetric.vue').default);
Vue.component('super-admin-search', require('./components/SuperAdminSearch.vue').default);
Vue.component('super-admin-user-utility-bar', require('./components/SuperAdminUserUtilityBar.vue').default);
Vue.component('basic-account-upgrade', require('./components/BasicAccountUpgrade.vue').default);

Vue.directive('input-has-content', require('./directives/InputHasContent').default);
Vue.directive('click-to-copy', require('./directives/ClickToCopy').default);
Vue.directive('phone-number-input', require('./directives/PhoneNumberInput').default);

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
Vue.prototype.$rollbar = new Rollbar({
    accessToken: 'a6c7dd7549b34be882c727da98030626',
    captureUncaught: true,
    captureUnhandledRejections: true,
});

Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
};


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
