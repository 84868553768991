<template>
    <button @click="openModal" class="button">
        <slot></slot>
    </button>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "DeleteUserButton",
        props: {
            name: {
                type: String,
                required: true
            },
            userId: {
                type: Number,
                required: true
            },
            redirect: {
                type: String,
            },
        },
        methods: {
            openModal() {
                EventBus.$emit('showUserDelete', {
                    name: this.name,
                    userId: this.userId,
                    redirect: this.redirect
                });
            }
        }
    }
</script>

<style scoped>

</style>