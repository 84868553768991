<template>
    <div class="no-gutters">
        <div class="row border-bottom my-2 my-lg-4">
            <div class="col-12 pl-0">
                <div class="card-body">
                    <h2 class="text-primary">{{ lang.get('assessment.welcome-to-the-gps-assessment') }}!</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 px-0">
                <div class="card-body">
                    <p class="text-lighter">{{ lang.get('assessment.the-following-questions-will-help') }}</p>
                    <div class="mt-2">
                        <p class="text-lighter mt-2 mb-2">
                            <span class="text-orange font-weight-bold text-capitalize">{{ lang.get('assessment.gifts') }}:</span>
                            {{ lang.get('assessment.gifts-description') }}
                        </p>
                        <p class="text-lighter mt-2 mb-2">
                            <span class="text-green font-weight-bold text-capitalize">{{ lang.get('assessment.passions') }}:</span>
                            {{ lang.get('assessment.passions-description') }}
                        </p>
                        <p class="text-lighter mt-2 mb-2">
                            <span class="text-blue font-weight-bold text-capitalize">{{ lang.get('assessment.story') }}:</span>
                            {{ lang.get('assessment.story-description') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <assessment-question-page-footer :loading="loading" @back="back" @next="$emit('next')"></assessment-question-page-footer>
    </div>
</template>

<script>
    import AssessmentQuestionPageFooter from './AssessmentQuestionPageFooter';

    export default {
        name: "AssessmentWelcomePage",
        components: {
            AssessmentQuestionPageFooter
        },
        props: {
            loading: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        computed: {
            lang() {
                return window.lang
            }
        },
        methods: {
            back() {
                window.location = '/dashboard'
            }
        }
    }
</script>

<style scoped>

</style>