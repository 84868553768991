<template>
    <div class="no-gutters" v-if="questionsToAnswer.length > 0">
        <div class="row my-2 my-lg-4">
            <div class="col-12 pl-0">
                <div class="card-body">
                    <h2 class="text-capitalize">{{ currentHeader }}</h2>
                    <div class="row d-none d-md-flex">
                        <div class="col-12 col-md-6 col-lg-5">
                            <h3 class="text-capitalize text-gray-3 font-display mb-0">{{ lang.get('assessment.statements') }}</h3>
                        </div>
                        <div class="col-12 col-md-6 col-lg-7">
                            <h3 class="text-capitalize text-gray-3 font-display mb-0">{{ lang.get('assessment.answers') }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row border-top mt-2 mt-lg-4" v-for="(question, index) of questionsToAnswer" :key="question.id">
            <div class="col-12 px-0">
                <div class="card-body">
                    <div class="row py-3">
                        <div class="col-12 col-md-6 col-lg-5">
                            <p class="font-weight-bold">{{ parseQuestion(question) }}</p>
                            <p class="font-size-small font-italic" v-if="question.default">Example: {{ parseExample(question) }}</p>
                        </div>
                        <div class="col-12 col-md-6 col-lg-7">
                            <div class="form-group">
                                <textarea v-model="answersToSubmit[index].text_value" style="min-height: 220px;" class="form-input" :id="question.id" :disabled="loading"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4" v-if="$slots.default">
            <div class="col-12">
                <h2 v-text="lang.get('assessment.your-current-gifts-passions-and-abilities')"></h2>
                <slot></slot>
            </div>
        </div>

        <assessment-question-page-footer :loading="loading || !canProgress()" @back="back" :go-back="goBack" @next="next"></assessment-question-page-footer>
    </div>
</template>

<script>
    import AssessmentQuestionPageFooter from './AssessmentQuestionPageFooter';
    import AssessmentMultiChoiceQuestionsPage from "./AssessmentMultiChoiceQuestionsPage";

    export default {
        name: "AssessmentTextQuestionsPage",
        extends: AssessmentMultiChoiceQuestionsPage,
        components: {
            AssessmentQuestionPageFooter
        },
        props: {
            currentHeader: {
                type: String,
                default() {
                    return window.lang.get('assessment.story')
                }
            }
        },
        computed: {
            lang() {
                return window.lang
            }
        },
        methods: {
            setQuestions(questions, previousAnswers=null) {
                this.questionsToAnswer = questions;

                this.answersToSubmit = questions.map(question => {
                    return { question_id: question.id, text_value: '' }
                });

                if (previousAnswers && !_.isEmpty(previousAnswers)) {
                    let vm = this;

                    for(let i = 0; i < this.answersToSubmit.length; i++) {
                        let answerValue = _.find(previousAnswers, function(answer) {
                            return answer.question_id === vm.answersToSubmit[i].question_id;
                        });

                        if (answerValue) {
                            this.answersToSubmit[i].text_value = answerValue.text_value;
                            this.answersToSubmit[i].id = answerValue.id;
                        }
                    }
                }
            },

            canProgress() {
                for (let i = 0; i < this.answersToSubmit.length; i++) {
                    let target = this.answersToSubmit[i];

                    if (target) {
                        if (_.isString(target.text_value)) {
                            if (target.text_value.length === 0) {
                                return false;
                            }
                        }
                    }
                }

                return true;
            }
        }
    }
</script>

<style scoped>

</style>
