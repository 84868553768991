<template>
    <div class="d-flex flex-row">
        <sort-filter class="mr-5" :categories="sortList"></sort-filter>
        <gift-passion-filter :list="gifts" class="mr-3" label="Gifts" @filter="filterGifts"></gift-passion-filter>
        <gift-passion-filter label="Passions" :list="passions" @filter="filterPassions"></gift-passion-filter>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";
    import GiftPassionFilter from './GiftPassionFilter';

    export default {
        name: "ChurchAdminUserFilters",
        components: {
            GiftPassionFilter
        },
        props: {
            gifts: Array,
            passions: Array,
        },
        data() {
            return {
                giftFilters: [],
                passionFilters: [],
            }
        },
        computed: {
            sortList() {
                return [
                    {key: "nameAsc", value: "A to Z"},
                    {key: "nameDesc", value: "Z to A"},
                ]
            }
        },
        methods: {
            filterGifts(filters) {
                if (!_.isEqual(this.giftFilters, filters)) {
                    this.giftFilters = filters;
                    EventBus.$emit('filter', this.passionFilters.concat(filters))
                }
            },
            filterPassions(filters) {
                if (!_.isEqual(this.passionFilters, filters)) {
                    this.passionFilters = filters;
                    EventBus.$emit('filter', this.giftFilters.concat(filters))
                }
            },
        }
    }
</script>

<style scoped>

</style>