<template>
    <div class="row mt-3 mt-lg-4">
        <div class="col-12 px-0">
            <div class="card-body">
                <div class="row">
                    <div class="col-6">
                        <button type="button" @click="$emit('back')" class="button w-icon large thin" :disabled="cannotGoBack()" v-html="lang.get('pagination.previous')"></button>
                    </div>
                    <div class="col-6 text-right">
                        <button type="button" @click="$emit('next')" class="button primary large thin" :disabled="cannotGoForward()" v-html="lang.get('pagination.next')"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AssessmentQuestionPageFooter",
        props: {
            loading: {
                type: Boolean,
                default() {
                    return false;
                }
            },
            goBack: {
                type: Boolean,
                default() {
                    return true
                }
            },
            goForward: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            lang() {
                return window.lang;
            }
        },
        methods: {
            cannotGoBack() {
                if (this.loading) {
                    return true;
                } else if (this.goBack === false) {
                    return true;
                }
            },
            cannotGoForward() {
                return this.loading || !this.goForward;
                // if (this.loading) {
                //     return true;
                // } else if (this.goForward === false) {
                //     return true;
                // }
            }
        }
    }
</script>

<style scoped>

</style>
