import Axios from 'axios';
import { EventBus } from '../EventBus';
import MetricCard from "../components/Metrics/MetricCard";

export default {
    components: {
        MetricCard
    },
    data() {
        return {
            id: null,
            title: '',
            graphData: {},
            loading: true
        }
    },
    computed: {
        axiosProvider() {
            return Axios.create({
                baseURL: '/dashboard/metrics/',
            });
        },
        colors() {
            return [
                '#00ACE6',
                '#9AD100',
                '#FF5A00',
            ];
        },
        tooltips() {
            return {
                formatTooltipX: d => (d + '').toUpperCase(),
                formatTooltipY: d => d
            }
        },
        height() {
            return 250;
        }
    },
    watch: {
        'dataSets': {
            handler: function(newValue) {
                this.graphData = newValue;
            },
            deep: true
        }
    },
    mounted() {
        this.updateChartData();
        this.id = `component-${this._uid}`;

        EventBus.$on('updateGraphs', (dates) => {
            this.updateChartData(dates.start, dates.end);
        });
    },
    methods: {
        initialDataSetValues() {
            if (this.dataSets && this.dataSets.length > 0) {
                return this.dataSets[0].values
            }

            return [];
        },

        /**
         * @name updateChartData
         * @param {string} start
         * @param {string} end
         */
        updateChartData(start, end) {

        }
    },
}
