<template>
    <div class="row mt-3" v-if="pagination.first_page_url">
        <div class="col-12 d-flex align-items-center justify-content-center">
            <button type="button" class="button icon left-arrow-gray mr-2" @click="changePage(1)" :disabled="onFirstPage()"></button>
            <button type="button" class="button circle mx-2"
                    @click="changePage(page)"
                    v-for="page in pages()"
                    :disabled="isCurrentPage(page)"
                    :class="{'active': page === pagination.current_page}"
            >{{ page }}</button>
            <button type="button" class="button icon right-arrow-gray ml-2" @click="changePage(pagination.last_page)" :disabled="onLastPage()"></button>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "Pagination",
        props: {
            offset: {
                type: Number,
                default() {
                    return 15
                }
            },
            loading: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                pagination: {
                    first_page_url: null,
                    current_page: null,
                    last_page: null,
                    last_page_url: null,
                    next_page_url: null,
                }
            }
        },
        mounted() {
            EventBus.$on('setPagination', data => {
                this.pagination = {
                    ...data
                }
            })
        },
        methods: {
            onFirstPage() {
                return this.pagination.current_page === 1;
            },
            onLastPage() {
                return this.pagination.current_page >= this.pagination.last_page;
            },
            isCurrentPage(page) {
                return page === this.pagination.current_page
            },
            pages() {
                let pages = [];
                let from = this.pagination.current_page - Math.floor(this.offset / 2);
                if (from < 1) {
                    from = 1;
                }
                let to = from + this.offset - 1;
                if (to > this.pagination.last_page) {
                    to = this.pagination.last_page;
                }
                while (from <= to) {
                    pages.push(from);
                    from++;
                }
                return pages;
            },
            changePage(page) {
                if (page > this.pagination.last_page) {
                    page = this.pagination.last_page;
                }
                EventBus.$emit('paginate', page);
                this.pagination.current_page = page;
            }
        }
    }
</script>

<style scoped>

</style>