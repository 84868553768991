<template>
    <form class="col-6 d-flex justify-content-end search-form" @submit.prevent="searchUsers">
        <button type="button" class="button orange thin flex-shrink-0 mr-3"  @click="clearSearch" v-if="submittedSearch.length > 0">Clear</button>
        <input type="text" v-model="search" class="form-input icon search" v-input-has-content placeholder="Search users" />
    </form>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "SuperAdminSearch",
        data() {
            return {
                search: '',
                submittedSearch: ''
            }
        },
        methods: {
            clearSearch() {
                EventBus.$emit('clearSearch');
                this.search = '';
                this.submittedSearch = '';
            },
            searchUsers() {
                this.submittedSearch = this.search;
                EventBus.$emit('search', this.search);
            }
        }
    }
</script>

<style scoped>

</style>
