<template>
    <div>
        <div class="row no-gutters hero-image hero-image__package-selection d-flex justify-content-center">
            <div class="justify-content-center">
                <h1>Package Selection:
                    <span v-if="package === 'silver'">{{ lang.get('packages.silver') }}</span>
                    <span v-if="package === 'gold'">{{ lang.get('packages.gold') }}</span>
                    <span v-if="package === 'platinum'">{{ lang.get('packages.platinum') }}</span>
                </h1>
                <p v-text="lang.get('packages.please-fill-form-make-purchase')"></p>
                <div class="cta pt-4">
                    <span class="d-block" v-text="lang.get('packages.Questions?')"></span>
                    <a href="https://meetings.hubspot.com/brian689/clone" target="_blank" class="btn btn-schedule d-inline-block" v-text="lang.get('packages.schedule-a-call')"></a>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="mb-3 mb-lg-5">
                <div class="row justify-content-center mb-3 mb-lg-4">
                    <div class="col-md-12 col-lg-6">

<!--                        <sign-up-errors :errors="errors"></sign-up-errors>-->

                        <form @submit.prevent="validateContent" class="card">
                            <div class="form-group">
                                <input type="text" class="form-input large"
                                    :class="{'is-invalid' : errors.first_name}"
                                    v-model="interface.first_name"
                                    :placeholder="lang.get('forms.first-name')"
                                       required
                                    :disabled="thinking" />
                                <validation-error v-if="errors.first_name">{{ errors.first_name[0] }}</validation-error>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-input large"
                                    :class="{'is-invalid' : errors.last_name}"
                                    v-model="interface.last_name"
                                    :placeholder="lang.get('forms.last-name')"
                                       required
                                    :disabled="thinking" />
                                <validation-error v-if="errors.last_name">{{ errors.last_name[0] }}</validation-error>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-input large"
                                    :class="{'is-invalid' : errors.email}"
                                    v-model="interface.email"
                                       required
                                    :placeholder="lang.get('forms.email-address')" :disabled="thinking" />
                                <validation-error v-if="errors.email">{{ errors.email[0] }}</validation-error>
                            </div>
                            <div class="form-group row" v-if="errors.email && errors.email[0] === lang.get('validation.unique', { 'attribute' : 'email' })">
                                <div class="col-12 d-flex my-2">
                                    <div class="flex-grow-1 mr-2 text-primary" v-text="lang.get('forms.appears-email-is-taken')"></div>
                                    <div>
                                        <a href="/login" class="button primary">{{ lang.get('auth.login') }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-input large"
                                    :class="{'is-invalid' : errors.password}"
                                    v-model="interface.password"
                                       required
                                    :placeholder="lang.get('forms.password')" :disabled="thinking" />
                                <validation-error v-if="errors.password">{{ errors.password[0] }}</validation-error>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-input large"
                                    :class="{'is-invalid' : errors.password_confirmation}"
                                    v-model="interface.password_confirmation"
                                       required
                                    :placeholder="lang.get('forms.password-confirmed')" :disabled="thinking" />
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-input large"
                                    :class="{'is-invalid' : errors.church_name}"
                                    v-model="interface.church_name"
                                       required
                                    :placeholder="lang.get('forms.church-name')" :disabled="thinking" />
                                <validation-error v-if="errors.church_name">{{ errors.church_name[0] }}</validation-error>
                            </div>
                            <div class="form-group">
                                <input type="text" v-phone-number-input class="form-input large"
                                    :class="{'is-invalid' : errors.phone_number}"
                                    v-model="interface.phone_number"
                                       required
                                    :placeholder="lang.get('forms.phone-number')" :disabled="thinking" />
                                <validation-error v-if="errors.phone_number">{{ errors.phone_number[0] }}</validation-error>
                            </div>
                            <div class="w-100 my-4 pt-4 border-top">
                                <h3 v-text="lang.get('forms.Location')"></h3>
                                <div class="row form-group">
                                    <div class="col-12 col-lg-7">
                                        <select class="form-input large" id="organization_country"
                                                name="organization_country"
                                                required
                                                v-model="formData.country"
                                                aria-label="country">
                                            <option disabled v-text="`-- ${lang.get('forms.select-a-country')} --`"></option>
                                            <option v-for="country of countries" :key="country">{{ country }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col-12 col-lg-7">
                                        <input type="text" class="form-input large"
                                            :class="{'is-invalid' : errors.city}"
                                            v-model="interface.city"
                                               :placeholder="lang.get('forms.city')"
                                               required
                                               :disabled="thinking" />
                                        <validation-error v-if="errors.city">{{ errors.city[0] }}</validation-error>
                                    </div>
                                    <div class="col-12 col-lg-5">
                                        <select class="form-input large" v-if="isUS()" :class="{'is-invalid' : errors.state}" v-model="formData.state" :disabled="thinking">
                                            <option disabled>-- {{ lang.get('forms.state') }} --</option>
                                            <option v-for="state of states" :value="state.key">{{ state.value }}</option>
                                        </select>
                                        <input type="text"
                                               class="form-input large"
                                               id="organization_state_non_us"
                                               name="organization_state"
                                               required
                                               v-model="formData.state"
                                               autocomplete="state"
                                               :placeholder="lang.get('forms.state')"
                                               v-if="!isUS()"
                                               :disabled="thinking"
                                               aria-label="state">
                                        <validation-error v-if="errors.state">{{ errors.state[0] }}</validation-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button @click="$emit('back')" type="button" class="button w-icon secondary" :disabled="thinking">
                                        <span v-html="lang.get('pagination.previous')"></span>
                                    </button>
                                </div>
                                <div class="col-6 text-right">
                                    <button type="submit" class="button large primary" :disabled="thinking" v-html="lang.get('pagination.next')">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "../services/ApiService";
    import SignUpErrors from './SignUpErrors';
    import ValidationError from "./ValidationError";

    export default {
        name: "UserRegistration",
        components: {
            SignUpErrors,
            ValidationError,
        },
        props: {
            formData: Object,
            states: Array,
            countries: Array,
            packageList: Array,
            package: String
        },
        computed: {
            lang() {
                return window.lang
            },
            interface: {
                get() {
                    return this.formData
                },
                set(val) {
                    this.$emit('update:formData', val);
                }
            },
        },
        mounted() {
            this.interface.state = this.states[0].key;
            window.scrollTo(0,0);
        },
        data() {
            return {
                thinking: false,
                errors: {}
            }
        },
        watch: {
            'interface.country'(newValue) {
                if (newValue !== 'United States') {
                    this.interface.state = '';
                } else {
                    this.interface.state = 'AL';
                }
            }
        },
        methods: {
            isUS() {
                return this.interface.country === 'United States'
            },

            packageSelected() {
                for(let i = 0; i < this.packageList.length; i++) {
                    if (this.packageList[i].name === this.package) {
                        return true;
                    }
                }
            },

            validateContent() {
                this.thinking = true;
                this.errors = {};

                ApiService.validateRegistrationContent(this.interface).then(response => {
                    this.$emit('validated');
                    this.thinking = false;
                }).catch(error => {
                    // clear password
                    this.interface.password = '';
                    this.interface.password_confirmation = '';

                    this.thinking = false;
                    this.errors = error.allErrors;
                    window.scrollTo(0,0);
                    console.error(error);
                })
            }
        },
    }
</script>

<style scoped>

</style>
